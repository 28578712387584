/* eslint-disable max-lines */
import { IApChargeCode } from '@invoice';
import { IRowNode } from 'ag-grid-community';
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useWatch
} from 'react-hook-form';
import { KColors, KContainer, KForm, KGrid, KImage, KInput } from 'uikit';
import * as yup from 'yup';

import {
  AirContainerMode,
  DataCal,
  InvoiceType,
  LevelType,
  OceanContainerMode
} from '@constants';
import { BottomActions } from 'components';
import { formatToFixed } from 'containers/FromWip/helpers';
import { Prototype } from 'core';
import { useResolverForm } from 'hooks';
import trans from 'translation';
import { ValidationUtils, useDefaultValue } from 'utils';

import FormInfoChargeCode from '../../../Form.InfoChargeCode';
import { amountValue, unitPriceKey } from '../../../helpers';

interface IFormData {
  chargeCodeUnit: any;
  totalQuantity: number;
  subTotal: number;
  paymentSubTotal: number;
  apChargeCodeLevels: any[];
}
interface IProps {
  node: IRowNode<IApChargeCode>;
  paymentCurrency: any;
  invoiceType: InvoiceType;
  contMode?: OceanContainerMode | AirContainerMode;
  dataCal: DataCal;
  callBackNodeData?: (v: any) => void;
}

const FormUnitPriceQuantityAP = ({
  node,
  paymentCurrency,
  invoiceType,
  contMode,
  dataCal,
  callBackNodeData
}: IProps) => {
  const { data: item } = node;

  const {
    chargeCode,
    apChargeCodeFrom,
    apChargeCodeTo,
    containerTypeSize,
    currency,
    exchangeRate,
    chargeCodeUnit,
    taxType
  } = item as IApChargeCode;

  const keyUnitPrice = unitPriceKey(contMode);

  const { itemChargeCodeLevels } = useDefaultValue();

  const defaultChargeCodeUnit = chargeCode[keyUnitPrice] || chargeCodeUnit;

  const { clientQuantity, chargeableQuantity, totalQuantityCal } =
    dataCal[defaultChargeCodeUnit?.code] || {};

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({
      totalQuantity: ValidationUtils.requiredNum().test(
        'sum',
        trans('message.message_equal_quantity'),
        function (v: number) {
          const arrChargeCode = this.parent.apChargeCodeLevels;
          const sum = (arrChargeCode || []).reduce((acc: number, curr: any) => {
            return acc + curr?.quantity;
          }, 0);

          return sum === v;
        }
      ),
      apChargeCodeLevels: yup.array().of(
        yup.object().shape({
          unitPrice: ValidationUtils.requiredNum(),
          quantity: ValidationUtils.requiredNum()
        })
      )
    }),
    configs: {
      values: {
        chargeCodeUnit: defaultChargeCodeUnit,
        totalQuantity: (item?.quantity || totalQuantityCal) ?? 1,
        subTotal: item?.subTotal ?? 0,
        paymentSubTotal: item?.paymentSubTotal ?? 0,
        apChargeCodeLevels: item?.apChargeCodeLevels?.length
          ? item?.apChargeCodeLevels
          : [
              {
                ...itemChargeCodeLevels,
                quantity: totalQuantityCal ?? 1,
                unitPrice: chargeCode?.minSelling ?? 1
              }
            ]
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'apChargeCodeLevels',
    keyName: 'formId'
  });

  const infoChargeCode = useMemo(() => {
    const list = [
      {
        label: 'charge_code',
        value: chargeCode?.code
      },
      {
        label: 'from',
        value: apChargeCodeFrom?.code
      },
      {
        label: 'to',
        value: apChargeCodeTo?.code
      },
      {
        label: 'container_size',
        value: containerTypeSize?.code
      },
      {
        label: 'min_selling_price',
        value: chargeCode?.minSelling,
        unit: chargeCode?.currency?.code || currency?.code
      },
      {
        label: 'ex_rate',
        value: exchangeRate,
        unit: paymentCurrency?.code || currency?.code
      }
    ];

    return list;
  }, [
    apChargeCodeFrom?.code,
    apChargeCodeTo?.code,
    chargeCode?.code,
    chargeCode?.currency?.code,
    chargeCode?.minSelling,
    containerTypeSize?.code,
    currency?.code,
    exchangeRate,
    paymentCurrency?.code
  ]);

  const [apChargeCodeLevels, totalQuantity] = useWatch({
    control: methods.control,
    name: ['apChargeCodeLevels', 'totalQuantity']
  });

  const isCreditNote = useMemo(() => {
    return invoiceType === InvoiceType.CREDIT_NOTE;
  }, [invoiceType]);

  const isLevelType = useMemo(
    () => chargeCode.levelType === LevelType.LevelType1, // có 2 level
    [chargeCode.levelType]
  );

  const resultQuantity = useMemo(() => {
    const sumListQuantity = (apChargeCodeLevels ?? [])?.reduce(
      (v: number, currentV: any) => {
        return Number(v) + (Number(currentV?.quantity) || 0);
      },
      0
    );
    return (Number(totalQuantity) || 0) - sumListQuantity;
  }, [apChargeCodeLevels, totalQuantity]);

  const isAddLevel = useMemo(() => {
    return isLevelType && resultQuantity > 0 && fields.length < 5;
  }, [fields.length, isLevelType, resultQuantity]);

  const lastRow = useCallback(
    (index: number) => fields?.length === index,
    [fields?.length]
  );

  const onRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const onAppend = useCallback(async () => {
    const isValid = await methods.trigger('apChargeCodeLevels');
    if (isValid) {
      append(
        {
          ...itemChargeCodeLevels,
          level: fields.length + 1,
          quantity: resultQuantity
        },
        { shouldFocus: false }
      );
    }
  }, [append, fields.length, itemChargeCodeLevels, methods, resultQuantity]);

  const {
    subTotal,
    paymentSubTotal,
    paymentTaxAmount,
    paymentTotalAmount,
    newChargeCodeLevels
  } = amountValue(isCreditNote, apChargeCodeLevels, exchangeRate, taxType);

  const onFormValid = useCallback(
    (data: IFormData) => {
      callBackNodeData?.({
        ...(item as IApChargeCode),
        quantity: data?.totalQuantity,
        subTotal: +formatToFixed(subTotal),
        paymentSubTotal: +formatToFixed(paymentSubTotal),
        chargeCodeUnit: data?.chargeCodeUnit,
        chargeCodeUnitId: data?.chargeCodeUnit?.id,
        paymentTaxAmount: +formatToFixed(paymentTaxAmount),
        paymentTotalAmount: +formatToFixed(paymentTotalAmount),
        apChargeCodeLevels: newChargeCodeLevels,
        balance: +formatToFixed(paymentTotalAmount)
      });
    },
    [
      callBackNodeData,
      item,
      newChargeCodeLevels,
      paymentSubTotal,
      paymentTaxAmount,
      paymentTotalAmount,
      subTotal
    ]
  );

  return (
    <KContainer.View>
      <KContainer.View dp="flex" row justifyContent="space-between">
        <FormInfoChargeCode infoChargeCode={infoChargeCode} />
      </KContainer.View>

      <KContainer.View marginT={'2.5rem'}>
        <FormProvider {...methods}>
          <KForm onSubmit={methods.handleSubmit(onFormValid)}>
            <KGrid.Container>
              <KGrid.Item xs={12}>
                <KGrid.Container display={'flex'} alignItems="baseline">
                  <KGrid.Item xs={3.5}>
                    <KContainer.Fieldset marginT="0.5rem" paddingT="1rem">
                      <KGrid.Container>
                        <KGrid.Item xs={12}>
                          <Controller
                            name="chargeCodeUnit"
                            control={methods.control}
                            render={({ field }) => {
                              return (
                                <KInput.Autocomplete
                                  {...field}
                                  label={trans('unit')}
                                  getOptionLabel={(o: any) => o?.name ?? ''}
                                  disabled
                                />
                              );
                            }}
                          />
                        </KGrid.Item>

                        <KGrid.Item xs={12}>
                          <Controller
                            name="totalQuantity"
                            control={methods.control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <KInput.TextField
                                  {...field}
                                  required
                                  label={trans('total_quantity')}
                                  message={error?.message}
                                />
                              );
                            }}
                          />
                        </KGrid.Item>

                        {/* <KGrid.Item xs={6}>
                          <KInput.TextField
                            name="clientQuantity"
                            label={trans('client_quantity')}
                            value={clientQuantity ?? ''}
                            disabled
                          />
                        </KGrid.Item>

                        <KGrid.Item xs={6}>
                          <KInput.TextField
                            name="carrierQuantity"
                            label={trans('carrier_quantity')}
                            value={chargeableQuantity ?? ''}
                            disabled
                          />
                        </KGrid.Item> */}

                        <KGrid.Item xs={6}>
                          <KInput.TextField
                            name="subTotal"
                            label={trans('with_unit.sub_total', {
                              unit: chargeCode?.currency?.code
                            })}
                            value={
                              Prototype.number.formatNumber(subTotal, {
                                precision: 2
                              }) ?? ''
                            }
                            disabled
                          />
                        </KGrid.Item>

                        <KGrid.Item xs={6}>
                          <KInput.TextField
                            name="paymentSubTotal"
                            sx={{
                              input: {
                                color: KColors.secondary.normal,
                                fontWeight: '700'
                              }
                            }}
                            label={trans('with_unit.payment_sub_total', {
                              unit: paymentCurrency?.code || currency?.code
                            })}
                            value={
                              Prototype.number.formatNumber(paymentSubTotal, {
                                precision: 2
                              }) ?? ''
                            }
                            readOnly
                          />
                        </KGrid.Item>
                      </KGrid.Container>
                    </KContainer.Fieldset>
                  </KGrid.Item>

                  <KGrid.Item xs={8.5}>
                    <KContainer.Fieldset marginT="0.5rem" paddingT="1rem">
                      <KGrid.Container>
                        {fields.map((itemV: any, index: number) => {
                          const estSubTotal =
                            (Number(apChargeCodeLevels[index]?.quantity) || 0) *
                            (Number(apChargeCodeLevels[index]?.unitPrice) || 0);

                          const estPaymentSubtotalItem =
                            (Number(estSubTotal) || 0) *
                            (Number(exchangeRate) || 0);

                          return (
                            <Fragment key={itemV?.formId}>
                              <KGrid.Item xs={1.5}>
                                <KInput.TextField
                                  name={`apChargeCodeLevels.${index}.level`}
                                  label={trans('level')}
                                  disabled
                                  value={itemV?.level ?? ''}
                                />
                              </KGrid.Item>

                              <KGrid.Item xs={1.5}>
                                <Controller
                                  name={`apChargeCodeLevels.${index}.quantity`}
                                  control={methods.control}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => {
                                    return (
                                      <KInput.TextField
                                        {...field}
                                        label={trans('quantity')}
                                        disabled={!lastRow(index + 1)}
                                        onChange={v => {
                                          field.onChange(v);
                                          methods.trigger('totalQuantity');
                                        }}
                                        message={error?.message}
                                      />
                                    );
                                  }}
                                />
                              </KGrid.Item>

                              <KGrid.Item xs={2}>
                                <Controller
                                  name={`apChargeCodeLevels.${index}.unitPrice`}
                                  control={methods.control}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => {
                                    return (
                                      <>
                                        <KInput.TextField
                                          {...field}
                                          sx={{
                                            input: {
                                              color: KColors.secondary.normal,
                                              fontWeight: '700'
                                            }
                                          }}
                                          label={trans('with_unit.unit_price', {
                                            unit: currency?.code
                                          })}
                                          message={error?.message}
                                        />
                                      </>
                                    );
                                  }}
                                />
                              </KGrid.Item>

                              <KGrid.Item xs={2.5}>
                                <Controller
                                  name={`apChargeCodeLevels.${index}.estTotal`}
                                  control={methods.control}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => {
                                    return (
                                      <KInput.TextField
                                        {...field}
                                        label={trans(
                                          'with_unit.level_sub_total',
                                          {
                                            unit: currency?.code
                                          }
                                        )}
                                        value={Prototype.number.formatNumber(
                                          estSubTotal,
                                          {
                                            precision: 2
                                          }
                                        )}
                                        message={error?.message}
                                        disabled
                                      />
                                    );
                                  }}
                                />
                              </KGrid.Item>

                              <KGrid.Item xs={3.5}>
                                <KInput.TextField
                                  sx={{
                                    input: {
                                      color: KColors.secondary.normal,
                                      fontWeight: '700'
                                    }
                                  }}
                                  label={trans(
                                    'with_unit.level_payment_sub_total',
                                    {
                                      unit:
                                        paymentCurrency?.code || currency?.code
                                    }
                                  )}
                                  value={
                                    Prototype.number.formatNumber(
                                      estPaymentSubtotalItem,
                                      {
                                        precision: 2
                                      }
                                    ) ?? ''
                                  }
                                  readOnly
                                />
                              </KGrid.Item>

                              <KGrid.Item xs={1} alignSelf={'end'}>
                                {lastRow(index + 1) && fields.length > 1 && (
                                  <KImage.MuiIcon
                                    cursor={'pointer'}
                                    color={KColors.secondary.normal}
                                    icon="HighlightOff"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  />
                                )}
                                {lastRow(index + 1) && isAddLevel && (
                                  <KImage.MuiIcon
                                    cursor={'pointer'}
                                    color={KColors.primary.normal}
                                    icon="AddCircleOutlineOutlined"
                                    onClick={onAppend}
                                  />
                                )}
                              </KGrid.Item>
                            </Fragment>
                          );
                        })}
                      </KGrid.Container>
                    </KContainer.Fieldset>
                  </KGrid.Item>
                </KGrid.Container>
              </KGrid.Item>
            </KGrid.Container>
            <BottomActions
              btnProps={[
                {
                  //   isLoading: modifyLoading
                }
              ]}
            />
          </KForm>
        </FormProvider>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(FormUnitPriceQuantityAP);
