import { IConsol } from '@dto';
import {
  IModifyLoadingDischargeDetails,
  IModifyConsolTransport
} from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';

import {
  ArApType,
  ENDPOINTS,
  InvoiceCategory,
  QUERY_KEYS,
  ServiceType,
  SystemChargeCodeUnit,
  WebTable
} from '@constants';
import APIManager, { END_POINTS } from '@services';
import { Prototype } from 'core';
import { formatSummary } from 'utils/common';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './shipment';
export * from './routing';
export * from './container';
export * from './accounting';

export const useFetchConsol = (id?: number | string) => {
  return useQueryEnhancer<IConsol | undefined>({
    queryKey: [QUERY_KEYS.consol, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id', { id }),
        body: {
          includeFields: ['freightPayment', 'flight', 'vessel']
        }
      });

      return Prototype.response.normalizeData(res.data, 'consolNumber');
    },
    enabled: !!id
  });
};

export const useCUDConsol = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.consol(''),
    webTable: WebTable.CONSOL,
    queryKey: QUERY_KEYS.consol
  });
};

export const useModifyLoadingDischargeDetails = (isConsol?: boolean) => {
  const queryClient = useQueryClient();

  return useMutationEnhancer<any, IModifyLoadingDischargeDetails>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: isConsol
          ? ENDPOINTS.consol(':id/routing-info', { id: data.id })
          : ENDPOINTS.shipment(':id/routing-info', { id: data.id }),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: [isConsol ? QUERY_KEYS.consol : QUERY_KEYS.shipment],
            type: 'active'
          },
          data
        );
      }
    }
  });
};

export const useFetchConsolDataForBreadcrumbs = (options: {
  id?: string | number;
  containerId?: string | number;
  shipmentId?: string | number;
}) => {
  const { id, containerId, shipmentId } = options;

  const { data: consol } = useQueryEnhancer<IConsol | undefined>({
    queryKey: [QUERY_KEYS.consol],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });

  const { data: shipment } = useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipment],
    queryFn: async () => {
      if (shipmentId) {
        const res = await APIManager.request({
          url: ENDPOINTS.shipment(':id', { id: shipmentId })
        });
        return res.data;
      }
      return null;
    }
  });

  const { data: shipmentContainer } = useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.shipmentContainer,
      shipmentId ? parseInt(shipmentId as string) : null,
      containerId ? parseInt(containerId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentContainer(':id', { id: containerId, shipmentId })
      });
      return res.data;
    },
    enabled: !!containerId && !!shipmentId
  });

  const { data: consolContainer } = useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.consolContainer,
      id ? parseInt(id as string) : null,
      containerId ? parseInt(containerId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consolContainer(':id', {
          id: containerId,
          consolId: id
        }),
        body: {
          includeFields: ['shipmentContainer']
        }
      });
      return res.data;
    },
    enabled: !!id && !!containerId && !shipmentId
  });

  return {
    consolNo: consol?.consolNumber ?? '',
    shipmentNo: shipment?.shipmentNumber ?? '',
    consolContainerNo: consolContainer?.containerNumber ?? '',
    shipmentContainerNo:
      shipmentContainer?.containerConsol?.containerNumber ?? containerId ?? '',
    shipment: {
      shipmentStatus: shipment?.shipmentStatus,
      bookingClient: shipment?.bookingClient
    }
  };
};

export const useFetchListBuyRateTransfer = (
  fromCurrencyId?: string | number,
  toCurrencyId?: string | number,
  createdDate?: string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.buyRateTransfer,
      fromCurrencyId,
      toCurrencyId,
      createdDate
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: END_POINTS.CONSOL.GET_BUY_RATE_TRANSFER,
        body: {
          fromCurrencyId,
          toCurrencyId,
          createdDate
        }
      });

      return res?.data || null;
    },
    enabled: !!fromCurrencyId && !!toCurrencyId
  });
};

export const useFetchGetListBuyTransfer = async (
  fromCurrencyId?: string | number,
  toCurrencyId?: string | number,
  createdDate?: string | null
) => {
  if (!!toCurrencyId && !!fromCurrencyId) {
    const res = await APIManager.request({
      url: END_POINTS.CONSOL.GET_BUY_RATE_TRANSFER,
      body: {
        fromCurrencyId,
        toCurrencyId,
        createdDate: Prototype.date.formatDB(createdDate ?? '')
      }
    });
    return res?.data || null;
  }
  return undefined;
};

export const useCUDTransport = (webTable: WebTable) => {
  return useCUDMutationEnhancer<IModifyConsolTransport>({
    endPoint: END_POINTS.CONSOL.GET_CONSOL_TRANSPORT,
    webTable: webTable
  });
};

export const useFetchSummaryConsol = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.consolSummary],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id/summary', { id })
      });

      let val = formatSummary(res.data);

      return val || null;
    },
    enabled: !!id
  });
};

export const useConsolCheckThreshold = (consolId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.consolCheckThreshold],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':consolId/check-threshold', { consolId })
      });

      return res?.data || null;
    },
    enabled: !!consolId
  });
};

export const useCalculateQuantityWithUnit = (
  invoiceCategory: InvoiceCategory,
  id?: string | number,
  params?: {
    chargeCodeUnitCode: SystemChargeCodeUnit;
    chargeCodeFromId: number;
    chargeCodeToId: number;
    containerTypeSizeId: number;
    screen: ArApType;
    serviceType?: ServiceType;
  }
) => {
  const { screen, serviceType, ...rest } = params || {
    serviceType: ServiceType.Customs,
    screen: ArApType.Ap
  };
  let key = 'consolId';

  switch (invoiceCategory) {
    case InvoiceCategory.Accounting:
      key = 'consolId';
      break;

    case InvoiceCategory.Billing:
      key = 'shipmentId';
      break;

    case InvoiceCategory.Service:
      key = 'serviceId';
      break;
  }

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.calculateQuantityWithUnit, invoiceCategory, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.wipAble(invoiceCategory, '/calculate-quantity', {
          screen,
          serviceType
        }),
        body: {
          [key]: id,
          ...rest
        }
      });

      return res?.data;
    },
    cacheTime: 0,
    enabled:
      !!id &&
      [
        SystemChargeCodeUnit.CON,
        SystemChargeCodeUnit.M3,
        SystemChargeCodeUnit.KG
      ].includes(params?.chargeCodeUnitCode as SystemChargeCodeUnit)
  });
};

export const useCUDNotesToClient = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.notesToClient(),
    webTable: WebTable.NOTES_TO_CLIENT
  });
};

type IProfiitAndLose = {
  revenue: number;
  cost: number;
  profit: number;
  proRev: number;
  proCost: number;
};
type IWipStatistics = {
  primary: IProfiitAndLose;
  secondary: IProfiitAndLose;
  primaryWithTax: IProfiitAndLose;
  secondaryWithTax: IProfiitAndLose;
};
export const useFetchWipStatistics = ({
  category,
  params: {
    consolId,
    shipmentId,
    serviceId,
    serviceType,
    secondCurrencyId,
    jobOrderId
  }
}: {
  category: InvoiceCategory;
  params: {
    consolId?: any;
    shipmentId?: any;
    jobOrderId?: any;
    // service requied for serviceType
    serviceId?: any;
    serviceType?: ServiceType;
    secondCurrencyId: number;
  };
}) => {
  const id = consolId || shipmentId || serviceId || jobOrderId;
  return useQueryEnhancer<IWipStatistics>({
    queryKey: [QUERY_KEYS.accountingStatistics, id, secondCurrencyId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.wipStatistics(category, '', {
          serviceType,
          jobOrderId
        }),
        body: {
          consolId,
          shipmentId,
          serviceId,
          jobOrderId,
          secondCurrencyId
        }
      });

      return res?.data;
    },
    keepPreviousData: false,
    enabled: !!id
  });
};

export const useFetchWip = ({
  category,
  params: { consolId, shipmentId, service, screen }
}: {
  category: InvoiceCategory;
  params: {
    screen: ArApType;
    consolId?: any;
    shipmentId?: any;
    // service requied for serviceType
    service?: {
      serviceId: any;
      serviceType: ServiceType;
    };
  };
}) => {
  const id = consolId || shipmentId || service?.serviceId;
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.wip + screen, id + screen],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.wipAble(category, '', {
          serviceType: service?.serviceType,
          screen
        }),
        body: {
          consolId: shipmentId ? '' : consolId,
          shipmentId,
          serviceId: service?.serviceId,
          includeFields: [
            'addrFrom',
            'addrTo',
            'arClient',
            'containerTypeSize',
            'chargeCodeUnit',
            'chargeCode',
            'currency',
            'taxType',
            'wipPrices',
            'wipSubChargeCodeList',
            'wipSubChargeCodes',
            'apClient',
            'onBehalfClient',
            'consolId'
          ]
        }
      });
      return res?.data;
    },
    cacheTime: 0,
    enabled: !!id
  });
};

export const useCUDWip = ({
  category,
  params: { serviceType, screen }
}: {
  category: InvoiceCategory;
  params: {
    screen: ArApType;
    // serviceType requied for service
    serviceType?: ServiceType;
  };
}) => {
  return usePageCUDMutationEnhancer({
    mutationKeys: [
      [QUERY_KEYS.wip + screen],
      [QUERY_KEYS.accountingStatistics],
      [QUERY_KEYS.sellingWip],
      [QUERY_KEYS.costingWip]
    ],
    endPoint: ENDPOINTS.wipAble(category, '', {
      serviceType: serviceType,
      screen
    }),
    webTable: WebTable.WIP,
    queryKey: QUERY_KEYS.wip,
    redirectPath: '.'
  });
};
