import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

import { AdviceType, GroupAccounting } from '@constants';
import AccountingLayout from 'pages/Accounting/DetailsLayout';

import { IAdminRoute } from './helpers';

const Dashboard = loadable(() =>
  pMinDelay(import('pages/Accounting/DashBoard'), 200)
);

const SalesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/DetailsLayout'), 200)
);

const SalesArInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/ArInvoice'), 200)
);

const PurchasesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/DetailsLayout'), 200)
);

const PurchasesApInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/ApInvoice'), 200)
);

const StatementLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/DetailsLayout'), 200)
);

const StatementOfAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementOfAccount'), 200)
);

const StatementOfAccountDetails = loadable(() =>
  pMinDelay(
    import('pages/Accounting/Statement/StatementOfAccount/Details'),
    200
  )
);

const StatementPayment = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment'), 200)
);

const StatementPaymentDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment/Details'), 200)
);

const StatementInvoices = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices'), 200)
);

const StatementInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices/Details'), 200)
);

const MoreLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/More/DetailsLayout'), 200)
);

const MoreChartOfAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ChartOfAccounts'), 200)
);

const MoreManageLinkedAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ManageLinkedAccount'), 200)
);

const MoreTaxCode = loadable(() =>
  pMinDelay(import('pages/Accounting/More/TaxCode'), 200)
);

const BankingLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/DetailsLayout'), 200)
);

const BankingBankAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/BankAccount'), 200)
);

const BankingBankTransaction = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/BankTransaction'), 200)
);

const BankingMoney = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/Money'), 200)
);

const BankingMoneyDetail = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/Money/Details'), 200)
);

const AccountingAdvice = loadable(() =>
  pMinDelay(import('pages/Accounting/Advice'), 200)
);

const AccountingAdviceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Advice/Details'), 200)
);

const accountingRoutes: IAdminRoute = {
  name: 'Accounting',
  path: 'accounting',
  code: [GroupAccounting.ACCOUNTING],
  element: <AccountingLayout />,
  icon: 'calculate',
  noChildren: true,
  children: [
    {
      parentKey: 'accounting',
      name: 'Dashboard',
      path: 'dashboard',
      code: [GroupAccounting.ACCOUNTING_DASHBOARD],
      element: <Dashboard />
    },
    {
      parentKey: 'accounting',
      name: 'Sales',
      path: 'sales',
      code: [
        GroupAccounting.ACCOUNTING_AR_INVOICE,
        GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE
        // GroupAccounting.ACCOUNTING_AR_INVOICE_SUMMARY
      ],
      element: <SalesLayout />,
      children: [
        {
          index: true,
          code: [GroupAccounting.ACCOUNTING_AR_INVOICE],
          element: <SalesArInvoice />
        },
        {
          path: 'receivable-advice',
          code: [GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE],
          children: [
            {
              index: true,
              code: [GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE],
              element: (
                <AccountingAdvice
                  key="receivableAdvice"
                  adviceType={AdviceType.Receivable}
                />
              )
            },
            {
              path: ':receivableAdviceId',
              code: [GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE],
              element: (
                <AccountingAdviceDetails
                  key=":receivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            },
            {
              path: 'new',
              code: [GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE],
              element: (
                <AccountingAdviceDetails
                  key="newReceivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            }
          ]
        }
        // {
        //   path: 'ar-client-summary',
        //   code: [GroupAccounting.ACCOUNTING_AR_INVOICE_SUMMARY],
        //   element: <ArClientSummary />
        // }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Purchases',
      path: 'purchases',
      code: [
        GroupAccounting.ACCOUNTING_AP_INVOICE,
        GroupAccounting.ACCOUNTING_PAYMENT_ADVICE
      ],
      element: <PurchasesLayout />,
      children: [
        {
          index: true,
          code: [GroupAccounting.ACCOUNTING_AP_INVOICE],
          element: <PurchasesApInvoice />
        },
        {
          path: 'payment-advice',
          code: [GroupAccounting.ACCOUNTING_PAYMENT_ADVICE],
          children: [
            {
              index: true,
              code: [GroupAccounting.ACCOUNTING_PAYMENT_ADVICE],
              element: (
                <AccountingAdvice
                  key="paymentAdvice"
                  adviceType={AdviceType.Payment}
                />
              )
            },
            {
              path: ':paymentAdviceId',
              code: [GroupAccounting.ACCOUNTING_PAYMENT_ADVICE],
              element: (
                <AccountingAdviceDetails
                  key="paymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            },
            {
              path: 'new',
              code: [GroupAccounting.ACCOUNTING_PAYMENT_ADVICE],
              element: (
                <AccountingAdviceDetails
                  key="newPaymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            }
          ]
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Statement',
      path: 'statement',
      element: <StatementLayout />,
      children: [
        {
          index: true,
          element: <StatementOfAccount />
        },
        {
          path: 'new',
          element: <StatementOfAccountDetails key="new" />
        },
        {
          path: ':statementOfAccountId',
          element: <StatementOfAccountDetails key=":statementOfAccountId" />
        },
        {
          path: 'statement-payment',
          children: [
            {
              index: true,
              element: <StatementPayment />
            },
            {
              path: 'new',
              element: <StatementPaymentDetails key="new" />
            },
            {
              path: ':paymentId',
              element: <StatementPaymentDetails key=":paymentId" />
            }
          ]
        },
        {
          path: 'statement-invoice',
          children: [
            {
              index: true,
              element: <StatementInvoices />
            },
            {
              path: ':statementInvoiceId',
              element: <StatementInvoiceDetails key=":statementInvoiceId" />
            }
          ]
        }
      ]
    },
    // {
    //   parentKey: 'accounting',
    //   name: 'Report',
    //   path: 'report',
    //   code: [GroupAccounting.ACCOUNTING],
    //   element: <AccountingReportLayout />,
    //   children: [
    //     {
    //       index: true,
    //       element: <div>Ar Invocie</div>
    //     },
    //     {
    //       path: 'receivable-advice',
    //       element: <div>receivable-advice</div>
    //     }
    //   ]
    // },
    {
      parentKey: 'accounting',
      code: [
        GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT,
        GroupAccounting.ACCOUNTING_LINKED_ACCOUNT,
        GroupAccounting.ACCOUNTING_TAX_CODE
      ],
      name: 'More',
      path: 'more',
      element: <MoreLayout />,
      children: [
        // {
        //   index: true,
        //   element: <div>General Journals</div>
        // },
        {
          path: 'chart-of-account',
          code: [GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT],
          element: <MoreChartOfAccount />
        },
        {
          path: 'manage-linked-account',
          code: [GroupAccounting.ACCOUNTING_LINKED_ACCOUNT],
          element: <MoreManageLinkedAccount />
        },
        {
          path: 'tax-code',
          code: [GroupAccounting.ACCOUNTING_TAX_CODE],
          element: <MoreTaxCode />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Banking',
      path: 'banking',
      code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT],
      element: <BankingLayout />,
      children: [
        {
          index: true,
          code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT],
          element: <BankingBankAccount />
        },
        {
          path: 'bank-transaction',
          code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT],
          element: <BankingBankTransaction />
        },
        {
          path: 'money',
          children: [
            {
              index: true,
              element: <BankingMoney />
            },
            {
              path: 'spend/:paymentSource/new',
              element: <BankingMoneyDetail key="spendNew" />
            },
            {
              path: 'spend/:paymentSource/:moneyId',
              element: <BankingMoneyDetail key=":spendMoneyId" />
            },
            {
              path: 'receive/:paymentSource/new',
              element: <BankingMoneyDetail key="receiveNew" />
            },
            {
              path: 'receive/:paymentSource/:moneyId',
              element: <BankingMoneyDetail key=":receiveMoneyId" />
            }
          ]
        }
      ]
    }
  ]
};

export default accountingRoutes;
