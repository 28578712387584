import { snakeCase } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { AdviceType, BankingPaymentSource } from '@constants';
import {
  useAlertMutationEnhancer,
  useCUDAdvice,
  useFetchAdvice,
  useFetchStatementOfAccount,
  useFetchStatementPayment,
  useFetchStatementInvoice
} from 'hooks';
import { useSpendReceiveMoney } from 'hooks/accounting/banking';
import trans from 'translation';
import { KBreadcrumbs } from 'uikit';

export const useDeleteAdvice = (adviceType: AdviceType) => {
  const { deleteMutation } = useCUDAdvice(adviceType, 'DELETE');

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useAccountingBreadcrumbs = (path: string) => {
  const { pathname } = useLocation();
  const {
    paymentAdviceId,
    receivableAdviceId,
    statementOfAccountId,
    paymentId,
    statementInvoiceId,
    moneyId
  } = useParams<any>();

  const source: BankingPaymentSource = pathname
    .match(/\/money\/([^\/]*)/)?.[1]
    ?.toUpperCase() as BankingPaymentSource;

  const { data: paymentAdvice } = useFetchAdvice(
    AdviceType.Payment,
    paymentAdviceId
  );

  const { data: receivableAdvice } = useFetchAdvice(
    AdviceType.Receivable,
    receivableAdviceId
  );

  const { data: statementPayment } = useFetchStatementPayment(paymentId);
  const { data: statementOfAccount } =
    useFetchStatementOfAccount(statementOfAccountId);
  const { data: statementInvoice } =
    useFetchStatementInvoice(statementInvoiceId);

  const { data: bankingPayment } = useSpendReceiveMoney({
    source,
    id: moneyId
  });

  const res = useMemo(() => {
    const arr = path
      .split('/')
      .slice(3)
      .filter(i => i);

    const bc = [trans('routes.accounting')];

    const subBc = arr.map((i, idx) => {
      let title = trans(`routes.${snakeCase(i)}`);
      if (/^[0-9]+$/.test(i)) {
        switch (idx) {
          case 1:
            title = statementOfAccount?.code ?? i;
            break;
          case 2:
            title =
              paymentAdvice?.code ??
              receivableAdvice?.code ??
              statementPayment?.code ??
              statementInvoice?.code ??
              i;
            break;
          case 4:
            title = bankingPayment?.code ?? i;
            break;
          default:
            break;
        }
      }

      return title;
    });

    return [...bc, ...subBc];
  }, [
    statementPayment?.code,
    path,
    paymentAdvice?.code,
    receivableAdvice?.code,
    bankingPayment?.code,
    statementInvoice?.code,
    statementOfAccount?.code
  ]);

  const breadcrumbs = useMemo(() => {
    return <KBreadcrumbs breadcrumbs={res} />;
  }, [res]);

  return breadcrumbs;
};
