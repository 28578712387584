import loadable from '@loadable/component';
import React, { useState, memo, useCallback } from 'react';

import { UITab } from '@constants';
import { useUITabsWithCallback } from 'hooks';
import trans from 'translation';
import { KContainer, KTab, KBreadcrumbs } from 'uikit';

import Client from './Client';
import Freight from './Freight';
import SaleMarketing from './Sales';

const Service = loadable(() => import('./Service'));
const Accounting = loadable(() => import('./Accounting'));
const Administration = loadable(() => import('./Administration'));
const Ecus = loadable(() => import('./Ecus'));
const Other = loadable(() => import('./Other'));

const Library = () => {
  const cb = useCallback(() => {
    return [
      {
        key: UITab.CategoryClient,
        label: trans('tab.client'),
        hasAccordion: true
      },
      {
        key: UITab.CategoryFreight,
        label: trans('tab.freight')
      },
      {
        key: UITab.CategoryService,
        label: trans('tab.service')
      },
      {
        key: UITab.CategoryAccounting,
        label: trans('tab.accounting')
      },
      {
        key: UITab.CategorySalesMarketing,
        label: trans('tab.sales_marketing')
      },
      // {
      //   key: 'report',
      //   label: trans('tab.report')
      // },
      {
        key: UITab.CategoryAdministration,
        label: trans('tab.administration'),
        hasAccordion: true
      },
      {
        key: UITab.CategoryEcus,
        label: trans('tab.ecus')
      },
      {
        key: UITab.CategoryOther,
        label: trans('tab.other'),
        hasAccordion: true
      }
    ];
  }, []);

  const tabs = useUITabsWithCallback(cb);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.library'), trans('routes.category')]}
      />

      <KTab.Main tabs={tabs} onChangeTab={setTabIndex} />

      <KContainer.View
        paddingH="0.75rem"
        paddingV="0.25rem"
        marginB="4rem"
        // marginT={tabs[tabIndex].hasAccordion ? 0 : '0.75rem'}
      >
        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryClient)}
        >
          <Client />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryFreight)}
        >
          <Freight />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryService)}
        >
          <Service />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryAccounting)}
        >
          <Accounting />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategorySalesMarketing)}
        >
          <SaleMarketing />
        </KTab.Panel>

        {/* <KTab.Panel value={tabIndex} index={5}>
          report
        </KTab.Panel> */}

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryAdministration)}
        >
          <Administration />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryEcus)}
        >
          <Ecus />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.key === UITab.CategoryOther)}
        >
          <Other />
        </KTab.Panel>
      </KContainer.View>
    </>
  );
};

export default memo(Library);
