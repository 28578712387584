import { IUseAgTableResult, UseDataTableResult } from '@hooks-dto';
import { WithAlertDialogProps, WithPopperProps, WithPopupProps } from '@ui';
import { createRef } from 'react';

import { GroupAirFreight, GroupOceanFreight, UITab } from './enum';

export * from './table';
export * from './query';
export * from './enum';
export * from './option';
export * from './freight';

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER + 100;

export const Z_INDEX_PRIORITY = {
  alert: MAX_SAFE_INTEGER,
  modal: MAX_SAFE_INTEGER - 1,
  overlay: MAX_SAFE_INTEGER - 1,
  toast: MAX_SAFE_INTEGER - 2,
  actionSheet: MAX_SAFE_INTEGER - 3,
  imageViewer: MAX_SAFE_INTEGER - 4,
  bottomSheet: MAX_SAFE_INTEGER - 5,
  popup: MAX_SAFE_INTEGER - 6,
  popper: MAX_SAFE_INTEGER - 7
};

export const alertRef = createRef<WithAlertDialogProps>();
export const popupRef = createRef<WithPopupProps>();
export const popperRef = createRef<WithPopperProps>();

export const tableRef: { [key: string]: UseDataTableResult | undefined } = {};
export const agTableRef: { [key: string]: IUseAgTableResult<any> | undefined } =
  {};

export const PAGE_DEFAULT = 0;
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_DEFAULT_TABLE = 15;
export const PAGE_SIZE_DEFAULT_MAX = 2147483647; // JAVA Max integer

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_DISPLAY = 'DD-MM-YYYY HH:mm';
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DDTHH:mm';
export const DATE_FORMAT_SHORT_TIMEZONE = 'YYYY-MM-DDT';
export const DATE_FORMAT_SHORT = 'DD/MM/YYYY';
export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';

export const TIMEZONE_DEFAULT = 51; // GMT+00:00

export const MAX_PRECISION = 12;

export const SCREEN_HEIGHT_FIXED = 334;
export const TABLE_BODY_MAX_HEIGHT = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px)`;
export const TABLE_BODY_MAX_HEIGHT_WITHOUT_ACTIONS = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px + 42px)`;
export const TABLE_BODY_MAX_HEIGHT_WITH_TABS = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px - 60px)`;

export const IS_EDIT_FORM_WIP = false;

export const UI_TAB_ITEM_PERS = {
  // Ocean Consolidation
  [UITab.OFConsolAccounting]: {
    summary: GroupOceanFreight.OCEAN_CONSOL_ACCOUNTING_SUMMARY,
    chargeCode: GroupOceanFreight.OCEAN_CONSOL_CHARGE_CODE,
    ar: GroupOceanFreight.OCEAN_CONSOL_AR_INVOICE,
    ap: GroupOceanFreight.OCEAN_CONSOL_AP_INVOICE
  },
  // Ocean Shipment
  [UITab.OFShipmentBilling]: {
    summary: GroupOceanFreight.OCEAN_SHIPMENT_BILLING_SUMMARY,
    chargeCode: GroupOceanFreight.OCEAN_SHIPMENT_CHARGE_CODE,
    ar: GroupOceanFreight.OCEAN_SHIPMENT_AR_INVOICE,
    ap: GroupOceanFreight.OCEAN_SHIPMENT_AP_INVOICE
  },
  // Air Consolidation
  [UITab.AFConsolAccounting]: {
    summary: GroupAirFreight.AIR_CONSOL_ACCOUNTING_SUMMARY,
    chargeCode: GroupAirFreight.AIR_CONSOL_CHARGE_CODE,
    ar: GroupAirFreight.AIR_CONSOL_AR_INVOICE,
    ap: GroupAirFreight.AIR_CONSOL_AP_INVOICE
  },
  // Air Shipment
  [UITab.AFShipmentBilling]: {
    summary: GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
    chargeCode: GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
    ar: GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
    ap: GroupAirFreight.AIR_SHIPMENT_AP_INVOICE
  },
  // Air Shipment
  [UITab.ServiceBilling]: {
    summary: GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
    chargeCode: GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
    ar: GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
    ap: GroupAirFreight.AIR_SHIPMENT_AP_INVOICE
  }
};
