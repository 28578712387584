import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

import ReportLayout from 'pages/Report/DetailsLayout';

const ReportExport = loadable(() =>
  pMinDelay(import('pages/Report/Export'), 200)
);

const ReportImport = loadable(() =>
  pMinDelay(import('pages/Report/Import'), 200)
);

const ReportLogistic = loadable(() =>
  pMinDelay(import('pages/Report/Logistic'), 200)
);
import { IAdminRoute } from './helpers';

const reportRoutes: IAdminRoute = {
  name: 'Report',
  path: 'report',
  element: <ReportLayout />,
  icon: 'insights',
  noChildren: true,
  children: [
    {
      parentKey: 'accounting',
      name: 'Export',
      path: 'export',
      element: <ReportExport />
    },
    {
      parentKey: 'accounting',
      name: 'Import',
      path: 'import',
      element: <ReportImport />
    },
    {
      parentKey: 'accounting',
      name: 'Logistics',
      path: 'logistics',
      element: <ReportLogistic />
    },
    {
      parentKey: 'accounting',
      name: 'Sales',
      path: 'sales',
      element: <div>sales</div>
    },
    {
      parentKey: 'accounting',
      name: 'Customer Service',
      path: 'customer-service',
      element: <div>customer_service</div>
    },
    {
      parentKey: 'accounting',
      name: 'Marketing',
      path: 'marketing',
      element: <div>marketing</div>
    }
  ]
};

export default reportRoutes;
