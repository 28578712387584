import {
  ArApType,
  BankingPaymentSource,
  ENDPOINTS,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';
import { usePageCUDMutationEnhancer, useQueryEnhancer } from 'hooks/core';
import { AP_CLIENT_TO } from 'pages/Accounting/Bankingg/Money/helpers';

export const useSpendReceiveMoney = ({
  source,
  id
}: {
  source: BankingPaymentSource;
  id?: string;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          source,
          path: ':id',
          query: { id }
        })
      });

      return res.data;
    },
    enabled: !!id,
    select(data: any) {
      if (!data.isFromWip) {
        const details = data.details.map((item: any) => {
          return {
            ...item,
            client: item.client
              ? { ...item.client, apClientTo: AP_CLIENT_TO.input }
              : null
          };
        });
        data.details = details;
        return data;
      } else {
        return data;
      }
    }
  });
};

const SOURCE_PARAMS = {
  [BankingPaymentSource.Spend]: {
    webTable: WebTable.SPEND_MONEY,
    queyKey: QUERY_KEYS.spendBankingPayment,
    arApType: ArApType.Ap
  },
  [BankingPaymentSource.Receive]: {
    webTable: WebTable.RECEIVE_MONEY,
    queyKey: QUERY_KEYS.receiveBankingPayment,
    arApType: ArApType.Ar
  }
};

export const useCUDBankingPayment = ({
  source,
  isFromWip
}: {
  source: BankingPaymentSource;
  isFromWip: boolean;
}) => {
  const mParams = SOURCE_PARAMS[source] || {};
  const endPoint = isFromWip
    ? ENDPOINTS.bankingPayment({
        source,
        path: '/from-wip'
      })
    : ENDPOINTS.bankingPayment({
        source
      });
  return usePageCUDMutationEnhancer({
    mutationKeys: [[mParams.queyKey]],
    endPoint,
    webTable: mParams.webTable,
    queryKey: mParams.queyKey
  });
};

export const useSpendReceiveWip = ({
  source,
  params
}: {
  source: BankingPaymentSource;
  params: {
    excludeIds: string[];
    clientId: string;
  };
}) => {
  const { clientId, excludeIds } = params || {};

  const mParams = SOURCE_PARAMS[source] || {};

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          source,
          path: `${mParams.arApType}-wip`,
          query: params
        }),
        body: {
          excludeIds,
          includeFields: [
            'chargeCode',
            'apClient',
            'arClient',
            'addrFrom',
            'addrTo',
            'taxType',
            'containerTypeSize',
            'chargeCodeUnit',
            'wipPrices',
            'wipSubChargeCodes',
            'onBehalfClient',
            'currency',
            'consol',
            'address'
          ],
          apClientId: clientId,
          arClientId: clientId
        }
      });

      return res.data;
    },
    enabled: !!clientId
  });
};
