import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MuiDrawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { snakeCase } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  drawerWidth,
  drawerWidthMini,
  sidebarListItem,
  sidebarCollapseListItem,
  sidebarListItemIcon,
  sidebarListItemText,
  sidebarListItemTextMini,
  sidebarListItemIconMini,
  sidebarListItemMini,
  sidebarCopyright
} from 'assets/theme/components/sidebar';
import { usePreferences } from 'context';
import { useUser } from 'hooks';
import { setupAdminRoutes } from 'hooks/core/helpers';
import adminRoutes from 'routes';
import { IAdminRoute } from 'routes/helpers';
import trans from 'translation';
import { KContainer, KImage } from 'uikit';
import { UIUtils } from 'utils';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: drawerWidthMini
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const PList = styled(MuiList)`
  padding-top: 0;
  padding-bottom: 0;
  max-height: calc(100vh - 55px);
  overflow: auto;
  margin-bottom: 45px;
`;

const List = styled(MuiList)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Sidebar = () => {
  const location = useLocation();
  const prefs = usePreferences();
  const { state } = prefs;

  const userData = useUser();

  const [parent, setParent] = useState('');

  const toggleParent = useCallback(
    (key: string) => {
      UIUtils.popper.dismiss();
      if (parent === key) {
        setParent('');
      } else {
        setParent(key);
      }
    },
    [parent]
  );

  const renderRoutes = useCallback(
    (routes: IAdminRoute[]): any =>
      routes.map(i => {
        const { children, icon, name, path, parentKey, noChildren } = i;

        const _path = parentKey ? `${parentKey}/${path}` : path;
        const isActive =
          path === ''
            ? ['/admin', '/admin/'].includes(location.pathname)
            : location.pathname.includes(`/admin/${_path}`);
        const isCollapseActive =
          parent === path || location.pathname.includes(`/admin/${_path}`);

        if (!parentKey && !noChildren && children) {
          return (
            <React.Fragment key={_path}>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    ...sidebarCollapseListItem(isCollapseActive),
                    ...(state.miniDrawer && sidebarListItemMini)
                  }}
                  onClick={() => toggleParent(path as string)}
                >
                  <Icon
                    fontSize="medium"
                    sx={{
                      ...sidebarListItemIcon,
                      ...(state.miniDrawer && sidebarListItemIconMini)
                    }}
                  >
                    {icon}
                  </Icon>
                  <ListItemText
                    disableTypography
                    primary={trans(`routes.${snakeCase(name)}`)}
                    sx={{
                      ...sidebarListItemText,
                      ...(state.miniDrawer && sidebarListItemTextMini)
                    }}
                  />
                  <Icon
                    fontSize="small"
                    sx={{ ...(state.miniDrawer && sidebarListItemTextMini) }}
                  >
                    {isCollapseActive ? 'expand_less' : 'expand_more'}
                  </Icon>
                </ListItemButton>
              </ListItem>

              <Collapse in={isCollapseActive} unmountOnExit>
                <List>{renderRoutes(children)}</List>
              </Collapse>
            </React.Fragment>
          );
        }

        return (
          <ListItem key={_path} disablePadding>
            <Link
              component={NavLink}
              to={`/admin/${_path}`}
              sx={{
                ...sidebarListItem(isActive, !!parentKey),
                ...(state.miniDrawer && sidebarListItemMini)
              }}
            >
              <Icon
                fontSize="medium"
                sx={{
                  ...sidebarListItemIcon,
                  ...(state.miniDrawer && sidebarListItemIconMini)
                }}
              >
                {icon}
              </Icon>
              <ListItemText
                disableTypography
                primary={name}
                sx={{
                  ...sidebarListItemText,
                  ...(state.miniDrawer && sidebarListItemTextMini)
                }}
              />
            </Link>
          </ListItem>
        );
      }),
    [location.pathname, parent, state.miniDrawer, toggleParent]
  );

  return (
    <Box component="nav" sx={{ width: drawerWidth, flexShrink: 0 }}>
      <Drawer
        variant="permanent"
        ModalProps={{ keepMounted: true }}
        open={!state.miniDrawer}
      >
        <KContainer.View center dp="flex" marginT="0.75rem">
          <KImage.Base
            source={
              !state.miniDrawer ? '/image/logo.webp' : '/image/logo_mini.webp'
            }
            size={!state.miniDrawer ? 90 : 35}
          />
        </KContainer.View>

        <PList>{renderRoutes(setupAdminRoutes(adminRoutes, userData))}</PList>

        {!state.miniDrawer && (
          <Box sx={sidebarCopyright}>&copy; 2024 Apollogix</Box>
        )}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
