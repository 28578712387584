import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  sheetDataWrapper: {
    '& .ag-header-cell': {
      '& .ag-cell-label-container': {
        position: 'relative',
        '& .ag-header-cell-menu-button': {
          position: 'absolute'
        }
      },
      '&.text-center': {
        '& .ag-header-cell-label': {
          justifyContent: 'center',
          textAlign: 'center',
          textWrap: 'wrap'
        },
        '& .ag-header-cell-comp-wrapper': {
          textAlign: 'center'
        }
      },
      '&.text-right .ag-header-cell-label': {
        justifyContent: 'end',
        textAlign: 'center'
      },
      '&.text-left .ag-header-cell-label': {
        justifyContent: 'start',
        textAlign: 'center'
      }
    },
    '& .ag-cell': {
      display: 'flex',
      alignItems: 'center',
      '&.text-center': {
        justifyContent: 'center',
        textWrap: 'wrap'
      },
      '&.text-right': {
        justifyContent: 'end'
      },
      '&.text-left': {
        justifyContent: 'start'
      },
      '& .ag-selection-checkbox': {
        marginRight: 0
      },
      '&.p-0': {
        padding: 0
      },
      '&.w-100': {
        '& .ag-cell-wrapper': {
          width: '100%'
        }
      },
      '&.unuse-focus:not(.ag-cell-range-selected):focus-within': {
        border: 'none'
      },
      '&.disable': {
        pointerEvents: 'none',
        opacity: 0.6
      }
    },
    // '& .ag-cell-inline-editing': {
    //   border: 'none!important',
    //   boxShadow: 'none',
    //   backgroundColor: 'unset'
    // },
    '&.disable-ag-body-viewport': {
      '& .ag-body-viewport': {
        pointerEvents: 'none',
        opacity: 0.9,
        cursor: 'not-allowed'
      }
    }
  }
});
