import React, { createRef, memo, useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { UITab } from '@constants';
import { useUITabsWithCallback } from 'hooks';
import trans from 'translation';
import { KContainer, KTab } from 'uikit';

const DetailsLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabsRef = createRef<any>();

  const cb = useCallback(() => {
    const _tabs = [
      {
        key: UITab.ReportExport,
        path: 'export',
        label: trans('tab.export')
      },
      {
        key: UITab.ReportImport,
        path: 'import',
        label: trans('tab.import')
      },
      {
        key: UITab.ReportLogistics,
        path: 'logistics',
        label: trans('tab.logistics')
      },
      {
        key: UITab.ReportSales,
        path: 'sales',
        label: trans('tab.sales')
      },
      {
        key: UITab.ReportCustomerService,
        path: 'customer-service',
        label: trans('tab.customer_service')
      },
      {
        key: UITab.ReportMarketing,
        path: 'marketing',
        label: trans('tab.marketing')
      }
    ];

    return _tabs;
  }, []);

  const tabs = useUITabsWithCallback(cb, {
    defFb: '/admin/report'
  });

  useEffect(() => {
    const arr = pathname.split('/').slice(3)[0] ?? '';
    if (tabs.find(i => i.path === arr)) {
      tabsRef.current?.onChange?.(
        tabs.findIndex(i => i.path === arr),
        true
      );
    }
  }, [pathname, tabs, tabsRef]);

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
    },
    [navigate, tabs]
  );

  if (tabs.length === 0) return null;

  return (
    <>
      <KTab.Main ref={tabsRef} tabs={tabs} onChangeTab={onChangeTab} />

      <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
        <Outlet />
      </KContainer.View>
    </>
  );
};

export default memo(DetailsLayout);
