import {
  IModifyServicePackingDetails,
  IModifyServicePenalty
} from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager, { END_POINTS } from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchServiceContainerDate = (
  serviceContainerId?: number | string | null,
  isLinkToShipment?: boolean,
  isDataService?: boolean
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceContainerDate, serviceContainerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.serviceContainer(':serviceContainerId/date', {
          serviceContainerId
        })
      });
      return res.data;
    },
    enabled: !!serviceContainerId && !isLinkToShipment && isDataService
  });
};

export const useFetchServiceContainerDetails = (
  serviceContainerId?: number | string | null,
  isLinkToShipment?: boolean,
  isDataService?: boolean
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceContainer, serviceContainerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.serviceContainer(
          'unlink-to-shipment/:serviceContainerId',
          {
            serviceContainerId
          }
        ),
        body: {
          includeFields: [
            'polCarrierPolicy',
            'podCarrierPolicy',
            'shipment',
            'client'
          ]
        }
      });
      return res.data;
    },
    enabled: !!serviceContainerId && !isLinkToShipment && isDataService
  });
};

export const useUpdateServiceContainerDate = (
  serviceContainerId?: number | string
) => {
  const queryClient = useQueryClient();
  const mutation = useMutationEnhancer<any, any>({
    mutationFn: async body => {
      const res = await APIManager.request({
        // url: generatePath(END_POINTS.SERVICE.SERVICE_CONTAINER_DATE, {
        //   serviceContainerId: serviceContainerId ?? ''
        // }),
        url: ENDPOINTS.serviceContainer(':serviceContainerId/date', {
          serviceContainerId
        }),
        method: 'PUT',
        showToast: true,
        body
      });
      return res?.data;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          { queryKey: [QUERY_KEYS.serviceContainerDate], type: 'active' },
          data
        );
      }
    },
    mutationKeys: [[QUERY_KEYS.serviceContainer]]
  });

  return {
    updateMutation: mutation.mutate,
    isLoading: mutation.isLoading
  };
};

export const useCUDServiceUnLinkToShipment = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: END_POINTS.SERVICE.UNLINK_TO_SHIPMENT,
    dependentWebTable: [WebTable.SERVICE_CONTAINER_PACKAGE],
    webTable: WebTable.SERVICE_CONTAINER,
    queryKey: QUERY_KEYS.serviceContainer
  });
};

export const useCUDServicePackingDetails = (
  serviceContainerId?: number | string
) => {
  return useCUDMutationEnhancer<IModifyServicePackingDetails>({
    mutationKeys: [[QUERY_KEYS.serviceContainer]],
    endPoint: ENDPOINTS.serviceContainer(':serviceContainerId/package', {
      serviceContainerId
    }),
    webTable: WebTable.SERVICE_CONTAINER_PACKAGE
  });
};

export const useCUDServicePenalTyDetails = (
  serviceContainerId?: number | string
) => {
  return useCUDMutationEnhancer<IModifyServicePenalty>({
    mutationKeys: [[QUERY_KEYS.serviceContainer]],
    endPoint: ENDPOINTS.serviceContainer(
      ':serviceContainerId/container-penalty',
      {
        serviceContainerId
      }
    ),
    webTable: WebTable.SERVICE_CONTAINER_PENALTY
  });
};
