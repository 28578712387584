import React, { memo, useMemo } from 'react';

import { NotificationLogSourceType, ReadStatus } from '@constants';
import { Prototype } from 'core';
import { useCUDNotificationLog } from 'hooks/notification';
import { KChip, KColors, KContainer, KGrid, KLabel, KLoading } from 'uikit';

const prefix = ({
  source,
  sourceId,
  content
}: {
  source: NotificationLogSourceType;
  sourceId: number;
  content: string;
}) => {
  let path = '';
  switch (source) {
    case NotificationLogSourceType.JOB_ORDER:
      path = 'sales/job-order';
      break;
    case NotificationLogSourceType.SYSTEM:
      path = 'help-support/case';
      break;
    case NotificationLogSourceType.SERVICE:
      path = 'service';
      break;
    case NotificationLogSourceType.SHIPMENT:
      const isOcean: boolean = content.includes('SOI');
      const PREFIX = {
        true: 'ocean-freight',
        false: 'air-freight'
      };
      path = PREFIX[`${isOcean}`] + '/shipment';
      break;
    case NotificationLogSourceType.AP_INVOICE:
      path = 'temporary';
      break;
    case NotificationLogSourceType.AR_INVOICE:
      path = 'temporary';
      break;
  }
  return `/admin/${path}/${sourceId}`;
};

const ItemNotification = ({ item }: any) => {
  const { mutate, isLoading } = useCUDNotificationLog();

  const urlRedirect = useMemo(() => {
    return prefix({
      source: item?.source,
      sourceId: item.sourceId,
      content: item.content
    });
  }, [item.content, item?.source, item.sourceId]);

  const timeAgo = Prototype.date.toLocal(item?.createdAt)?.fromNow();
  return (
    <KContainer.Touchable
      onPress={() => {
        if (item.readStatus !== 'READ') {
          mutate(
            {
              id: item?.id
            },
            {
              onSuccess() {
                window.open(urlRedirect, '_self');
              }
            }
          );
        } else {
          window.open(urlRedirect, '_self');
        }
      }}
    >
      {isLoading && <KLoading />}
      <KGrid.Container marginY={'0.5rem'}>
        <KGrid.Item xs={3}>
          <KChip
            size="md"
            brC={KColors.warning.normal}
            background={KColors.opacity.warning['16']}
            label={item?.source}
          />
        </KGrid.Item>

        <KGrid.Item xs={8.2}>
          <KLabel.Paragraph typo="TextLgMedium">
            {item?.subject}
          </KLabel.Paragraph>
          <KLabel.Paragraph
            marginV={'0.25rem'}
            dangerouslySetInnerHTML={{
              __html: item?.content.replace(/\n/g, '<br>')
            }}
          ></KLabel.Paragraph>
          <KLabel.Paragraph typo="TextXsNormal">
            {` sent by ${item?.sentUsername} at ${timeAgo}`}
          </KLabel.Paragraph>
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={item?.readStatus !== ReadStatus.READ}
          >
            <KGrid.Item xs={0.5}>
              <KContainer.View
                height={'0.8rem'}
                width={'0.8rem'}
                br={'2x'}
                background={KColors.primary.normal}
              ></KContainer.View>
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KGrid.Container>
    </KContainer.Touchable>
  );
};
export default memo(ItemNotification);
