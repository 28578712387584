/* eslint-disable max-lines */
import {
  IPaymentAdviceFormData,
  IReceivableAdviceFormData,
  IWipFormData
} from '@form-dto';
import React, { useCallback, useMemo } from 'react';

import {
  AirOceanType,
  ARInvoiceStatus,
  APInvoiceStatus,
  AdviceStatus,
  AdviceType,
  ClientType,
  UIDataType
} from '@constants';
import { Prototype } from 'core';
import trans from 'translation';
import { KChip, KColors, KContainer } from 'uikit';
import { mappedConsolStatusData, mappedShipmentStatusData } from 'utils';

import RequestUtils from './request';

export const checkApWarningStatus = (v: APInvoiceStatus) => {
  const invalidStatus: { [key in any]: boolean } = {
    [APInvoiceStatus.APPROVED]: true,
    [APInvoiceStatus.COMPLETE]: true,
    [APInvoiceStatus.CANCEL]: true
  };
  return !invalidStatus[v];
};

/**
 *
 * @param id Current Record Id
 * @param freightMode Ocean | Air
 * @returns
 */
export const useNormalizeData = (
  id?: number | string,
  freightMode: AirOceanType = AirOceanType.Ocean
) => {
  const normalizeWipData = useCallback(
    (data: IWipFormData) => {
      const {
        wipPrices: _wipPrices,
        wipSubChargeCodes: _wipSubChargeCodes,

        category,
        pId,
        cId,

        ...rest
      } = data;

      const wipPrices = _wipPrices.map((o: any) => ({
        ...o,
        estTotal: Number(o?.quantity || 0) * Number(o?.unitPrice || 0)
      }));

      const wipSubChargeCodes = _wipSubChargeCodes.map((v: any) => {
        const { subChargeCode, ...subRest } = v;
        return {
          ...subRest,
          subChargeCodeId: subChargeCode?.id
        };
      });

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'apClient',
            'penaltyType',
            'chargeCode',
            'currency',
            'addrFrom',
            'addrTo',
            'containerTypeSize',
            'taxType',
            'chargeCodeUnit'
          ]
        }),
        wipPrices,
        wipSubChargeCodes
      };

      if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else if (category === UIDataType.Sm) {
        mParams.shipmentId = pId;
        if (cId) {
          mParams.consolId = cId;
        }
      } else {
        mParams.serviceId = pId;
      }

      return mParams;
    },
    [id]
  );

  const normalizeAdviceData = useCallback(
    (data: IReceivableAdviceFormData | IPaymentAdviceFormData) => {
      const { resources, adviceDetails, adviceType, ...rest } = data;

      const resourceIds = resources.map((o?: any) => o?.id);

      const isPayment = adviceType === AdviceType.Payment;

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'currency',
            'paymentMode',
            'companyBank',
            'accountingNote',
            // Receivable
            'accountReceivable',
            'accountReceivableAddress',
            // Payment
            'accountPayable',
            'accountPayableAddress'
          ]
        }),
        resourceIds,
        paymentAdviceDetails: isPayment
          ? adviceDetails.map((val: any) => {
              return {
                apInvoiceId: val.id,
                id: val.id,
                amount: val.amount
              };
            })
          : [],
        receivableAdviceDetails: !isPayment
          ? adviceDetails.map((val: any) => {
              return {
                arInvoiceId: val.id,
                id: val.id,
                amount: val.amount
              };
            })
          : []
      };

      return mParams;
    },
    [id]
  );

  return {
    normalizeWipData,
    normalizeAdviceData
  };
};

export const useNormalizeInitValues = (
  category: UIDataType = UIDataType.Cs,
  mode: AirOceanType = AirOceanType.Ocean
) => {
  const normalizePaymentAdviceInitValues = useCallback((item?: any) => {
    const initValues: IPaymentAdviceFormData = {
      id: item?.id,
      code: item?.code,
      adviceType: AdviceType.Payment,

      accountPayable: item?.accountPayable,
      accountPayableAddress: item?.accountPayableAddress,
      defaultAdviceStatus: item?.paymentAdviceStatus,
      paymentAdviceStatus: item?.paymentAdviceStatus ?? AdviceStatus.Pending,

      refNo: item?.refNo ?? '',
      currency: item?.currency,
      totalAmount: item?.totalAmount,
      paymentMode: item?.paymentMode,
      companyBank: item?.companyBank,
      description: item?.description ?? '',
      defaultResources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      resources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],

      adviceDetails: (item?.paymentAdviceDetails ?? []).map((val: any) => {
        return {
          ...val.apInvoice,
          accountPayable: item?.accountPayable,
          amount: val.amount
        };
      }),

      accountingNote: item?.accountingNote,

      isLinkToMisa: item?.isLinkToMisa ?? false,
      syncStatus: item?.syncStatus
    };

    return initValues;
  }, []);

  const normalizeReceivableAdviceInitValues = useCallback((item?: any) => {
    const initValues: IReceivableAdviceFormData = {
      id: item?.id,
      code: item?.code,
      adviceType: AdviceType.Receivable,

      accountReceivable: item?.accountReceivable,
      accountReceivableAddress: item?.accountReceivableAddress,
      defaultAdviceStatus: item?.receivableAdviceStatus,
      receivableAdviceStatus:
        item?.receivableAdviceStatus ?? AdviceStatus.Pending,

      refNo: item?.refNo ?? '',
      currency: item?.currency,
      totalAmount: item?.totalAmount,
      paymentMode: item?.paymentMode,
      companyBank: item?.companyBank,
      description: item?.description ?? '',

      defaultResources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      resources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],

      adviceDetails: (item?.receivableAdviceDetails ?? []).map((val: any) => {
        return {
          ...val.arInvoice,
          accountReceivable: item?.accountReceivable,
          amount: val.amount
        };
      }),

      accountingNote: item?.accountingNote,

      isLinkToMisa: item?.isLinkToMisa ?? false,
      syncStatus: item?.syncStatus
    };

    return initValues;
  }, []);

  return {
    normalizePaymentAdviceInitValues,
    normalizeReceivableAdviceInitValues
  };
};

export const commonStatusArInvoice = (arInvoiceStatus: ARInvoiceStatus) => {
  const valueStatus = {
    allUpdate: 'allUpdate',
    backNext: 'backNext',
    notUpdate: 'notUpdate',
    back: 'back'
  };

  const statusUpdate = {
    [ARInvoiceStatus.DRAFT]: valueStatus.allUpdate,
    [ARInvoiceStatus.CONFIRM_REQUEST]: valueStatus.allUpdate,
    [ARInvoiceStatus.CONFIRMED]: valueStatus.backNext,
    [ARInvoiceStatus.INVOICE_REQUEST]: valueStatus.backNext,
    [ARInvoiceStatus.ISSUED]: valueStatus.back,
    [ARInvoiceStatus.COMPLETE]: valueStatus.notUpdate,
    [ARInvoiceStatus.CANCEL]: valueStatus.notUpdate
  };

  return {
    isDraftConfirmRequest:
      statusUpdate[arInvoiceStatus] === valueStatus.allUpdate,
    isNextBack: statusUpdate[arInvoiceStatus] === valueStatus.backNext,
    isComplete: statusUpdate[arInvoiceStatus] === valueStatus.notUpdate,
    isIssued: statusUpdate[arInvoiceStatus] === valueStatus.back,
    isCancel: arInvoiceStatus === ARInvoiceStatus.CANCEL
  };
};

export const commonStatusApInvoice = (apInvoiceStatus: APInvoiceStatus) => {
  const valueStatus = {
    allUpdate: 'allUpdate',
    backNext: 'backNext',
    notUpdate: 'notUpdate',
    back: 'back'
  };

  const statusUpdate = {
    [APInvoiceStatus.DRAFT]: valueStatus.allUpdate,
    [APInvoiceStatus.PAYMENT_REQUEST]: valueStatus.allUpdate,
    [APInvoiceStatus.VERIFIED]: valueStatus.backNext,
    [APInvoiceStatus.APPROVED]: valueStatus.back,
    [APInvoiceStatus.COMPLETE]: valueStatus.notUpdate,
    [APInvoiceStatus.CANCEL]: valueStatus.notUpdate
  };

  return {
    isDraftPaymentRequest:
      statusUpdate[apInvoiceStatus] === valueStatus.allUpdate,
    isNextBack: statusUpdate[apInvoiceStatus] === valueStatus.backNext,
    isCompleteCancel: statusUpdate[apInvoiceStatus] === valueStatus.notUpdate,
    isApproved: statusUpdate[apInvoiceStatus] === valueStatus.back,
    isCancel: apInvoiceStatus === APInvoiceStatus.CANCEL
  };
};

export const commonStatusInvoice = (
  invoiceStatus: ARInvoiceStatus | APInvoiceStatus,
  clientType: ClientType.AccountPayable | ClientType.AccountReceivable
) => {
  switch (clientType) {
    case ClientType.AccountReceivable:
      const arInvoice = commonStatusArInvoice(invoiceStatus as ARInvoiceStatus);
      return {
        isDraft: arInvoice.isDraftConfirmRequest,
        isNextBack: arInvoice.isNextBack,
        isComplete: arInvoice.isComplete,
        isApprovedIssued: arInvoice.isIssued,
        isCancel: arInvoice.isCancel
      };
    default:
      const apInvoice = commonStatusApInvoice(invoiceStatus as APInvoiceStatus);
      return {
        isDraft: apInvoice.isDraftPaymentRequest,
        isNextBack: apInvoice.isNextBack,
        isComplete: apInvoice.isCompleteCancel,
        isApprovedIssued: apInvoice.isApproved,
        isCancel: apInvoice.isCancel
      };
  }
};

export const useDefaultValue = () => {
  const itemChargeCodeLevels = {
    level: 1,
    quantity: 1,
    unitPrice: 1
  };

  const itemSubChargeCodes = {
    subChargeCode: null,
    currency: null,
    ratio: 0,
    subTotal: 0
  };

  return { itemChargeCodeLevels, itemSubChargeCodes };
};

export const useBookingCancelView = (item?: any) => {
  const bookingCancel = useMemo(() => {
    if (item?.isBookingCancel) {
      return (
        <KChip
          background={KColors.hexToRgba(KColors.secondary.normal, 0.31)}
          brC={KColors.transparent}
          label={trans('cancel')}
          textTransform={'uppercase'}
          color={KColors.secondary.normal}
          typo="TextMdMedium"
          padding="0.25rem"
          marginL="1.5rem"
        />
      );
    }

    return null;
  }, [item?.isBookingCancel]);

  if (!item) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {bookingCancel}
    </KContainer.View>
  );
};

export const useStatusView = (item?: any, isCs?: boolean) => {
  const props = useMemo(
    () =>
      isCs
        ? mappedConsolStatusData()[item?.consolStatus]
        : mappedShipmentStatusData()[item?.shipmentStatus],
    [isCs, item?.consolStatus, item?.shipmentStatus]
  );

  const status = useMemo(
    () => (
      <KChip
        textTransform="uppercase"
        typo="TextXMdMedium"
        padding="0.5rem"
        marginH="3.5rem"
        {...props}
      />
    ),
    [props]
  );

  const bookingCancel = useMemo(() => {
    if (item?.isBookingCancel) {
      return (
        <KChip
          background={KColors.hexToRgba(KColors.secondary.normal, 0.31)}
          brC={KColors.transparent}
          label={trans('booking_cancel')}
          textTransform={'uppercase'}
          color={KColors.secondary.normal}
          typo="TextXMdMedium"
          padding="0.5rem"
        />
      );
    }

    return null;
  }, [item?.isBookingCancel]);

  if (!item) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {status}
      {bookingCancel}
    </KContainer.View>
  );
};

export const checkLinkToShipment = (shipmentId?: string | number) => {
  return !!shipmentId;
};
