import {
  ArApType,
  ENDPOINTS,
  InvoiceCategory,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  ServiceType,
  WebTable,
  WIP_INCLUDE_FIELDS
} from '@constants';
import APIManager from '@services';
import { PaymentSource } from 'pages/Accounting/Bankingg/Money/helpers';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchInvoiceWip = (
  invoiceCategory: InvoiceCategory,
  accountType: ArApType,
  bodyParams: any
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.invoiceWip, invoiceCategory, accountType, bodyParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoiceWip(invoiceCategory, accountType),
        body: {
          ...bodyParams,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });
      return res.data?.data ?? [];
    },
    cacheTime: 0,
    enabled: !!invoiceCategory && !!accountType
  });
};

export const useFetchSellingWip = (
  category: InvoiceCategory,
  mParams: {
    consolId?: string | number;
    shipmentId?: string | number;
    serviceId?: string | number;
    jobOrderId?: string | number;
    serviceType?: ServiceType;
  }
) => {
  const { serviceType, consolId, shipmentId, serviceId, jobOrderId } =
    mParams || {};

  const arApType = ArApType.Ar;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.sellingWip, category, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              '',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType),
        body: {
          consolId: shipmentId ? undefined : consolId,
          shipmentId,
          serviceId,
          jobOrderId,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i,
        taxType: i.taxType ? { ...i.taxType, rate: i.taxRate ?? 0 } : undefined
      }));
    },
    cacheTime: 0,
    enabled: !!category
  });
};

export const useFetchCostingWip = (
  category: InvoiceCategory,
  bodyParams: {
    consolId?: string | number;
    shipmentId?: string | number;
    serviceId?: string | number;
    jobOrderId?: string | number;
    serviceType?: ServiceType;
  }
) => {
  const { serviceType, consolId, shipmentId, serviceId, jobOrderId } =
    bodyParams || {};

  const arApType = ArApType.Ap;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.costingWip, category, bodyParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              '',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType),
        body: {
          consolId: shipmentId ? undefined : consolId,
          shipmentId,
          serviceId,
          jobOrderId,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i,
        taxType: i.taxType ? { ...i.taxType, rate: i.taxRate ?? 0 } : undefined
      }));
    },
    cacheTime: 0,
    enabled: !!category
  });
};

export const useCUDSellingWip = ({
  category,
  serviceType
}: {
  category: InvoiceCategory;
  serviceType?: ServiceType;
}) => {
  return usePageCUDMutationEnhancer({
    mutationKeys: [[QUERY_KEYS.accountingStatistics], [QUERY_KEYS.sellingWip]],
    endPoint: serviceType
      ? ENDPOINTS.serviceWip2(
          {
            arApType: ArApType.Ar,
            category
          },
          '',
          {
            serviceType: serviceType.toLowerCase()
          }
        )
      : ENDPOINTS.wip2(category, ArApType.Ar),
    webTable: WebTable.LOCAL_CONSOL_SELLING_WIP,
    dependentWebTable: [
      WebTable.LOCAL_SHIPMENT_SELLING_WIP,
      WebTable.LOCAL_SERVICE_SELLING_WIP
    ],
    noRedirect: true
  });
};

export const useCUDCostingWip = ({
  category,
  serviceType
}: {
  category: InvoiceCategory;
  serviceType?: ServiceType;
}) => {
  return usePageCUDMutationEnhancer({
    mutationKeys: [
      [QUERY_KEYS.accountingStatistics],
      [QUERY_KEYS.costingWip],
      [QUERY_KEYS.sellingWip]
    ],
    endPoint: serviceType
      ? ENDPOINTS.serviceWip2(
          {
            arApType: ArApType.Ap,
            category
          },
          '',
          {
            serviceType: serviceType.toLowerCase()
          }
        )
      : ENDPOINTS.wip2(category, ArApType.Ap),
    webTable: WebTable.LOCAL_CONSOL_COSTING_WIP,
    dependentWebTable: [
      WebTable.LOCAL_SHIPMENT_COSTING_WIP,
      WebTable.LOCAL_SERVICE_COSTING_WIP
    ],
    noRedirect: true
  });
};

export const useFetchTaxType = ({
  id,
  paymentSource
}: {
  id: number;
  paymentSource: PaymentSource;
}) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.taxType],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.taxType(':id', { id })
      });

      return res.data;
    },
    enabled: paymentSource === PaymentSource.WITHOUT_JOB
  });
};

interface ICalculateQuantityWipInitParams {
  category: InvoiceCategory;
  serviceType?: ServiceType;
  consolId?: number | string;
  shipmentId?: number | string;
  serviceId?: number | string;
}

interface ICalculateQuantityWipParams {
  chargeCodeUnitCode: string;
}

export interface ICalculateQuantityWipResponse {
  quantity: number | null;
}

export const useCalculateQuantityWip = ({
  category,
  serviceType,
  arApType,
  ...rest
}: ICalculateQuantityWipInitParams & { arApType: ArApType }) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

export const useCalculateQuantitySellingWip = ({
  category,
  serviceType,
  ...rest
}: ICalculateQuantityWipInitParams) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType: ArApType.Ar,
                category
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, ArApType.Ar, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

export const useCalculateQuantityCostingWip = ({
  category,
  serviceType,
  ...rest
}: ICalculateQuantityWipInitParams) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                category,
                arApType: ArApType.Ap
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, ArApType.Ap, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

interface IWipTemplateParams {
  consolId?: string | number;
  shipmentId?: string | number;
  serviceId?: string | number;
}

export const useFetchWipTemplate = (
  arApType: ArApType,
  mParams: IWipTemplateParams
) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.wipTemplate, arApType, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.quotationTemplate('invoice'),
        body: {
          includeFields: ['chargeCodes'],
          size: PAGE_SIZE_DEFAULT_MAX,
          ...mParams,
          isSellingTemplate: arApType === ArApType.Ar
        }
      });

      return res.data?.data ?? [];
    }
  });
};
