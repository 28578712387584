import { IPermissionOfStaff } from '@dto';
import {
  IGrantStaffRolesParams,
  IRevokeStaffRolesParams,
  ISaveStaffPermissionsParams
} from '@request-dto';
import { generatePath } from 'react-router-dom';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable,
  tableRef
} from '@constants';
import APIManager, { END_POINTS } from '@services';

import {
  useMutationEnhancer,
  useQueryEnhancer,
  useGenericMutationEnhancer
} from '../core';

export const useRevokeStaffRoles = () => {
  return useMutationEnhancer<boolean | undefined, IRevokeStaffRolesParams>({
    mutationFn: async data => {
      const { staffId, ...rest } = data;

      const res = await APIManager.request({
        url: generatePath(END_POINTS.STAFF.GET_STAFF_ROLE_LIST, {
          id: staffId
        }),
        method: 'DELETE',
        showToast: true,
        body: { ...rest }
      });

      return res.success;
    },
    onSuccess: (data, variables) => {
      if (data) {
        tableRef?.[`${WebTable.STAFF_ROLE}-${variables.branchId}`]?.init?.();
        tableRef?.[
          `${WebTable.STAFF_PERMISSION}-${variables.branchId}`
        ]?.init?.();
      }
    }
  });
};

export const useGrantStaffRoles = (branchId: number) => {
  return useGenericMutationEnhancer<IGrantStaffRolesParams>({
    endPoint: END_POINTS.STAFF.GET_STAFF_ROLE_LIST,
    method: 'POST',
    webTable: `${WebTable.STAFF_ROLE}-${branchId}` as WebTable,
    dependentWebTable: [`${WebTable.STAFF_PERMISSION}-${branchId}` as WebTable]
  });
};

export const useFetchStaffPermissions = (
  staffId: string | number | undefined,
  branchId: number
) => {
  return useQueryEnhancer<IPermissionOfStaff[] | undefined>({
    queryKey: [QUERY_KEYS.staffPermissionList, staffId, branchId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/staff-role/permissions', { id: staffId }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          branchId
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i,
        extraStatus: i.extra
          ? JSON.parse(i.extra)
              ?.status?.split(',')
              ?.filter((s: string) => s)
              ?.map((s: string) => ({
                id: s,
                name: s
              })) ?? []
          : [],
        extraCheckedStatus: i.extra
          ? JSON.parse(i.extra)
              ?.checkedStatus?.split(',')
              ?.filter((s: string) => s)
              ?.map((s: string) => ({
                id: s,
                name: s
              })) ?? []
          : []
      }));
    },
    enabled: !!staffId
  });
};

export const useSaveStaffPermissions = (branchId: number) => {
  return useGenericMutationEnhancer<ISaveStaffPermissionsParams>({
    endPoint: END_POINTS.STAFF.GET_STAFF_PERMISSION_LIST,
    method: 'PUT',
    webTable: `${WebTable.STAFF_PERMISSION}-${branchId}` as WebTable
  });
};
