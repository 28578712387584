import { ICellRendererParams } from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import React from 'react';

import { ENDPOINTS, InvoiceType, LevelMarkup } from '@constants';
import { Prototype } from 'core';
import { onShowChargeableExRate } from 'pages/OceanFreight/Shipment/Billing/AR/Details/ChargeCodeList/helper';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput, KLabel } from 'uikit';
import { UIUtils } from 'utils';

export const ChargeableExchangeRateEditor = ({
  node,
  api
}: ICellRendererParams<any>) => {
  return (
    <KLabel.Text>
      {Prototype.number.formatNumber(node.data?.chargeableExchangeRate, {
        precision: 12
      })}{' '}
      {node.data?.chargeCode && (
        <KButton.Icon
          icon="MoreHoriz"
          tintColor={KColors.white}
          background={KColors.primary.normal}
          br="x"
          size="sm"
          onPress={() => {
            onShowChargeableExRate(node, v => {
              const { paymentSubTotal, paymentTaxAmount, paymentTotalAmount } =
                calcPayment({
                  taxType: node?.data?.taxType,
                  estSubTotal: node?.data?.subTotal as number,
                  exchangeRate: v.chargeableExchangeRate
                });
              const newValue = {
                ...node.data,
                ...v,
                paymentSubTotal,
                paymentTaxAmount,
                paymentTotalAmount
              };
              api.applyTransaction({
                update: [newValue]
              });
              node?.updateData(newValue);
              UIUtils.popup.dismiss();
            });
          }}
          marginL="0.25rem"
        />
      )}
    </KLabel.Text>
  );
};

export const SubChargeCodeCellRenderer = ({
  node
}: ICellRendererParams<any>) => {
  // const { isCreditNote, type, paymentCurrency } =
  //   colDef?.cellRendererParams as {
  //     isCreditNote: boolean;
  //     paymentCurrency: any;
  //     type: 'AP' | 'AR';
  //   };
  // const isCal =
  //   node.data?.chargeCode?.calculatorType === CalculatorType.Composite;
  const subChargeCodes =
    node.data?.arSubChargeCodes || node.data?.apSubChargeCodes || [];
  return (
    <KContainer.View dp="flex" row>
      {subChargeCodes.map((o: any, idx: number) => {
        const marginL = idx === 0 ? 0 : '0.5rem';
        return (
          <KContainer.View
            key={`sub-charge_code-list-${idx}`}
            dp="flex"
            center
            paddingV="0.25rem"
            paddingH="0.75rem"
            br="x"
            brW={1}
            brC={KColors.warning.normal}
            background={'#FFF5E5'}
            marginL={marginL}
          >
            <KLabel.Text>{o?.subChargeCode?.code}</KLabel.Text>
          </KContainer.View>
        );
      })}

      {/* <KLabel.Text>
        {node.data?.chargeCode && isCal && (
          <KButton.Icon
            icon="MoreHoriz"
            tintColor={KColors.white}
            background={KColors.warning.normal}
            br="x"
            size="sm"
            onPress={() => {
              onShowSubChargeCodeComposite(
                type,
                node,
                paymentCurrency,
                v => {
                  node.updateData(v);
                  api.applyTransaction({
                    update: [v]
                  });
                  UIUtils.popup.dismiss();
                },
                isCreditNote
              );
            }}
            marginL="0.25rem"
          />
        )}
      </KLabel.Text> */}
    </KContainer.View>
  );
};

export const ChartOfAccountCellEditor = ({
  node,
  api,
  data,
  onValueChange,
  ...props
}: CustomCellEditorProps<any>) => {
  const value = props.value?.revenueAccount || data?.revenueAccount;
  return (
    <KInput.Autocomplete
      value={value}
      fullWidth
      inputProps={{ marginT: '0rem' }}
      label={trans(' ')}
      apiURL={ENDPOINTS.chartOfAccount()}
      getOptionLabel={(o: any) => o?.displayName ?? ''}
      onChange={(v: any) => {
        const newData = {
          ...(node.data as any),
          revenueAccount: v,
          revenueAccountId: v?.id
        };
        onValueChange(v);
        node.updateData(newData);
        api.applyTransaction({
          update: [newData]
        });
      }}
    />
  );
};

export const SubTotalCellRender = ({ node }: ICellRendererParams<any>) => {
  const val = Number(node.data?.subTotal) || 0;
  const formatVal = formaValCurrency(val);
  return (
    <KLabel.Text>
      {formatVal} -{' '}
      {node.data?.currency?.code || node.data?.chargeCode?.currency?.code}
    </KLabel.Text>
  );
};

export const isOnBehalfOfClient = (invoiceType: InvoiceType) => {
  return invoiceType === InvoiceType.OBH ? true : false;
};

export const calcPayment = ({
  taxType,
  estSubTotal = 0,
  exchangeRate = 0
}: {
  taxType: any;
  estSubTotal: number;
  exchangeRate: number;
}) => {
  const _estSubTotal = isNaN(estSubTotal) ? 0 : estSubTotal;
  const _exchangeRate = isNaN(exchangeRate) ? 0 : exchangeRate;
  const paymentSubTotal = _estSubTotal * _exchangeRate;
  const paymentTaxAmount =
    paymentSubTotal * ((taxType?.defaultRate ?? 0) / 100);
  const paymentTotalAmount = paymentSubTotal + paymentTaxAmount;
  return {
    paymentSubTotal: +formatToFixed(paymentSubTotal),
    paymentTaxAmount: +formatToFixed(paymentTaxAmount),
    paymentTotalAmount: +formatToFixed(paymentTotalAmount)
  };
};
export const mapFromWipToTableAp = (item: any, exchangeRate: any) => {
  const apWipId = item?.id;
  delete item?.id;
  const { paymentSubTotal, paymentTaxAmount, paymentTotalAmount } = calcPayment(
    {
      taxType: item?.taxType,
      estSubTotal: item?.estSubTotal,
      exchangeRate
    }
  );
  return {
    ...item,
    description: item?.description,
    apWipId,
    exchangeRate,
    subTotal: item?.estSubTotal,
    paymentSubTotal,
    paymentTotalAmount,
    paymentTaxAmount,
    apChargeCodeFrom: item?.addrFrom,
    apChargeCodeTo: item?.addrTo,
    apChargeCodeFromId: item?.addrFromId,
    apChargeCodeToId: item?.addrToId,
    apSubChargeCodes: item?.wipSubChargeCodes,
    apChargeCodeLevels: item?.wipPrices,
    costAccount: item?.chargeCode?.costAccount,
    costAccountId: item?.chargeCode?.costAccountId
  };
};

export const mapFromWipToTableAr = (item: any, exchangeRate: any) => {
  const arWipId = item?.id;
  delete item?.id;
  const { paymentSubTotal, paymentTaxAmount, paymentTotalAmount } = calcPayment(
    {
      taxType: item?.taxType,
      estSubTotal: item?.estSubTotal,
      exchangeRate
    }
  );
  return {
    ...item,
    description: item?.description,
    arWipId,
    exchangeRate,
    chargeableExchangeRate: exchangeRate,
    subTotal: item?.estSubTotal,
    markupLevelNumber: LevelMarkup.MarkupLevel1,
    paymentSubTotal,
    paymentTotalAmount,
    paymentTaxAmount,
    arChargeCodeFrom: item?.addrFrom,
    arChargeCodeTo: item?.addrTo,
    arChargeCodeFromId: item?.addrFromId,
    arChargeCodeToId: item?.addrToId,
    arSubChargeCodes: item?.wipSubChargeCodes,
    arChargeCodeLevels: item?.wipPrices,
    revenueAccount: item?.chargeCode?.revenueAccount,
    revenueAccountId: item?.chargeCode?.revenueAccountId
  };
};

export const formaValCurrency = (val: number) => {
  const formatVal =
    val < 0
      ? `(${Prototype.number.formatNumber(-1 * val)})`
      : Prototype.number.formatNumber(val);

  return formatVal;
};

export const formatToFixed = (v: number) => {
  return v?.toFixed(2) || '0';
};

export const amountValue = (
  isCreditNote: boolean,
  chargeCodeLevels?: any,
  rate?: any,
  taxType?: any
) => {
  const newChargeCodeLevels = (chargeCodeLevels || []).map((item: any) => {
    return {
      ...item,
      estTotal: +formatToFixed(
        (Number(item?.quantity) || 0) * (Number(item?.unitPrice) || 0)
      ),
      unitPrice: +formatToFixed(Number(item?.unitPrice) || 0)
    };
  });

  const _subTotal = (newChargeCodeLevels ?? []).reduce(
    (v: number, currentV: any) => {
      const vAmount =
        (Number(currentV?.quantity) || 0) * (Number(currentV?.unitPrice) || 0);
      return Number(v) + vAmount;
    },
    0
  );

  const subTotal = isCreditNote ? -1 * _subTotal : _subTotal;

  const paymentSubTotal = (Number(subTotal) || 0) * (Number(rate) || 0);

  const paymentTaxAmount =
    ((Number(taxType?.defaultRate) || 0) * (Number(paymentSubTotal) || 0)) /
    100;

  const paymentTotalAmount =
    (Number(paymentSubTotal) || 0) + (Number(paymentTaxAmount) || 0);
  return {
    subTotal,
    paymentSubTotal,
    paymentTaxAmount,
    paymentTotalAmount,
    newChargeCodeLevels
  };
};

export const amountComposite = (
  isCreditNote: boolean,
  subChargeCodes?: any[],
  baseValue?: number,
  rate?: number,
  taxType?: any
) => {
  const newSubChargeCode = (subChargeCodes || []).map(itemA => {
    return {
      ...itemA,
      subChargeCode:
        itemA?.subChargeCode?.subChargeCode || itemA?.subChargeCode,
      subChargeCodeId:
        itemA?.subChargeCode?.subChargeCode?.id || itemA?.subChargeCode?.id,
      estTotal:
        ((Number(itemA?.subTotal) || 0) * (Number(itemA?.ratio) || 0)) / 100
    };
  });

  const _subTotal =
    (newSubChargeCode || []).reduce((acc, cur) => {
      acc = acc + cur.estTotal;
      return acc;
    }, 0) + (Number(baseValue) || 0);

  const subTotal = isCreditNote ? -1 * _subTotal : _subTotal;

  const paymentSubTotal = (Number(subTotal) || 0) * (Number(rate) || 0);

  const paymentTaxAmount =
    ((Number(taxType?.defaultRate) || 0) * (Number(paymentSubTotal) || 0)) /
    100;

  const paymentTotalAmount = paymentSubTotal + paymentTaxAmount;

  return {
    newSubChargeCode,
    subTotal,
    paymentSubTotal,
    paymentTaxAmount,
    paymentTotalAmount
  };
};
