import React, { useCallback } from 'react';

import {
  ConsolType,
  ExchangeRateTypeALL,
  ExchangeRateTypeExport,
  ExchangeRateTypeImport,
  InvoiceDate,
  PicType,
  generateOptions
} from '@constants';
import { Prototype } from 'core';
import trans from 'translation';
import { KColors, KInput } from 'uikit';

import UIUtils from './ui';

interface ITypes {
  title: string;
  onPress: () => void;
  icon: any;
  color?: any;
}

export const actionType = (types: ITypes) => {
  const { onPress = () => {}, title, icon, color } = types;
  return {
    title: trans(title),
    icon: {
      name: icon,
      color: color || KColors.primary.normal
    },
    onPress
  };
};

export const defaultPersonInCharges = {
  department: undefined,
  staff: undefined,
  // ratio: 0,
  picType: null
};

// export const defaultPersonInChart = (
//   personInCharges: any[] = [],
//   serviceType?: 'TRANSPORT' | 'HANDLING' | 'CUSTOMS'
// ) => {
//   const _personInCharges = personInCharges.map(itemP => {
//     return {
//       ...itemP,
//       staff: {
//         ...itemP?.staff,
//         jobTitle: itemP?.jobTitle
//       }
//     };
//   });
//   return personInCharges?.length
//     ? serviceType
//       ? _personInCharges.filter((item: any) => item.serviceType === serviceType)
//       : _personInCharges
//     : [{ ...defaultPersonInCharges, serviceType }];
// };

export const formatReqPIC = (item: any) => {
  return item?.staff?.id
    ? {
        id: item?.id,
        staffId: item?.staff?.id,
        departmentId: item?.department?.id
      }
    : null;
};

export const convertPIC = (item: any) => {
  return {
    ...item,
    staff: !!item?.staff
      ? {
          ...item?.staff,
          jobTitle: item?.jobTitle
        }
      : undefined
  };
};

export const recordPersonInChart = (
  personInCharges: any[],
  type:
    | null
    | 'CUSTOMS'
    | 'TRANSPORT'
    | 'HANDLING'
    | 'MAIN'
    | 'CUSTOMER_SERVICE'
) => {
  let newPerson = personInCharges.map(itemP => {
    return convertPIC(itemP);
  });
  let res = newPerson.find((item: any) => {
    return item?.picType === type;
  });

  return res;
};

export const defaultPersonInChart = (personInCharges: any[] = []) => {
  let _listDefaultPer = [
    { ...defaultPersonInCharges, picType: PicType.MAIN },
    { ...defaultPersonInCharges, picType: PicType.CUSTOMS },
    { ...defaultPersonInCharges, picType: PicType.TRANSPORT },
    { ...defaultPersonInCharges, picType: PicType.HANDLING },
    { ...defaultPersonInCharges, picType: PicType.CUSTOMER_SERVICE }
  ];

  if (recordPersonInChart(personInCharges, PicType.MAIN)) {
    _listDefaultPer[0] = recordPersonInChart(personInCharges, PicType.MAIN);
  }

  if (recordPersonInChart(personInCharges, PicType.CUSTOMS)) {
    _listDefaultPer[1] = recordPersonInChart(personInCharges, PicType.CUSTOMS);
  }

  if (recordPersonInChart(personInCharges, PicType.TRANSPORT)) {
    _listDefaultPer[2] = recordPersonInChart(
      personInCharges,
      PicType.TRANSPORT
    );
  }

  if (recordPersonInChart(personInCharges, PicType.HANDLING)) {
    _listDefaultPer[3] = recordPersonInChart(personInCharges, PicType.HANDLING);
  }

  if (recordPersonInChart(personInCharges, PicType.CUSTOMER_SERVICE)) {
    _listDefaultPer[4] = recordPersonInChart(
      personInCharges,
      PicType.CUSTOMER_SERVICE
    );
  }

  return _listDefaultPer;
};
/**
  format1= {
     pic1: null,
    pic2: null,
    pic3: null,
    pic4: null
    }
 *   
     format2= {
    picMain: null;
    picTransport: null;
    picHandling: null;
    picCustoms: null;
}
 */

export const convertPersonInCharges = (personInCharges: any[]) => {
  const pics = (personInCharges || [])
    .filter((itemD: any) => {
      return !!itemD?.department?.id || itemD?.isSales;
    })
    .map(itemX => {
      return {
        id: itemX?.id || null,
        staffId: itemX?.staff?.staffId ?? itemX?.staff?.id,
        departmentId: itemX?.department?.id,
        ratio: itemX?.ratio,
        picType: itemX?.picType
      };
    });

  const format1: Record<
    string,
    { staffId: number; departmentId: number; ratio: number } | null
  > = {
    // pic1: null,
    // pic2: null,
    // pic3: null,
    // pic4: null
  };
  pics.forEach((pic, index) => {
    // if (index < 4) {
    const key = `pic${index + 1}` as keyof typeof format1;
    format1[key] = {
      staffId: pic.staffId,
      departmentId: pic.departmentId,
      ratio: pic.ratio || 0
      // };
    };
  });

  const format2 = pics.reduce(
    (acc: any, curr: any) => {
      let key =
        curr.picType === PicType.MAIN
          ? 'SalesPerson'
          : `${curr.picType}`
              .split('_')
              .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
              .join('');

      key = 'pic' + key; // picCustom || picCustomerService

      acc[key] = curr;
      return acc;
    },
    {
      picMain: null,
      picTransport: null,
      picHandling: null,
      picCustoms: null,
      picCustomerService: null
    }
  );
  return {
    format1,
    format2
  };
};

export const formatSummary = (data: any = {}) => {
  Object.keys(data).map(
    x => (data[x] = Prototype.number.formatNumber(data[x]))
  );
  return data;
};

export const convertItem = () => {
  const addDefaultValue = useCallback((item: any, option: any) => {
    return { ...item, ...option };
  }, []);
  return { addDefaultValue };
};

export const showAlertWarning = (
  message:
    | 'message.all_invoice_will_be_deleted'
    | 'message.all_charge_codes_will_be_deleted'
    | string,
  callbackSubmit: () => void
) => {
  {
    UIUtils.alert.open({
      message: trans(message),
      buttons: [
        {
          title: trans('yes'),
          kind: 'danger',
          color: KColors.warning.normal,
          onPress: () => callbackSubmit()
        },
        {
          title: trans('no')
        }
      ]
    });
  }
};

export const useBasicAction = () => {
  const actionAdd = (onPress: () => void) => {
    return actionType({
      title: 'new_statement_of_account',
      icon: 'AddBox',
      onPress
    });
  };

  const actionSendStatus = (onPress: () => void, title?: string) => {
    return actionType({
      title: title ?? 'send_status',
      icon: 'ArrowUpward',
      onPress
    });
  };

  const actionCancel = (onPress: () => void) => {
    return actionType({
      title: 'cancel',
      icon: 'CancelOutlined',
      onPress,
      color: KColors.secondary.normal
    });
  };

  const actionPrint = (onPress: () => void) => {
    return actionType({
      title: 'export_soa',
      icon: 'CloudDownloadOutlined',
      onPress
    });
  };

  return { actionAdd, actionSendStatus, actionCancel, actionPrint };
};

export const convertHtml = (content: string) => {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const optionExchangeRateType = (dataType: ConsolType) => {
  const isImport = dataType === ConsolType.Import;
  if (isImport) {
    return generateOptions(ExchangeRateTypeImport);
  }

  const isExport = dataType === ConsolType.Export;
  if (isExport) {
    return generateOptions(ExchangeRateTypeExport);
  }

  return generateOptions(InvoiceDate);
};

export const exchangeRateDateValue = (
  v: ExchangeRateTypeALL,
  isEdit: boolean,
  timeImport: any,
  timeExport: any,
  createdAt: any
) => {
  let newDate: any = Prototype.date.now();
  if (v === ExchangeRateTypeALL.ETA) {
    // import
    newDate = Prototype.date.toLocal(timeImport);
  } else if (v === ExchangeRateTypeALL.ETD) {
    // export
    newDate = Prototype.date.toLocal(timeExport);
  } else if (v === ExchangeRateTypeALL.INVOICE_DATE) {
    if (isEdit) {
      newDate = createdAt;
    }
  }

  return newDate;
};

export const showNoteContent = (item?: any) => {
  UIUtils.popup.open({
    title: trans('general_note'),
    maxWidth: 'md',
    content: () => (
      <KInput.TextField multiline minRows={4} value={item?.noteContent ?? ''} />
    )
  });
};
