/* eslint-disable max-lines */
import { throttle } from 'lodash';
import React, { memo, useMemo, useState } from 'react';

import { BankingPaymentSource, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { calcPayment } from 'containers/FromWip/helpers';
import { useGlobalTable, useLocalTable, useNewButton } from 'hooks';
import { useSpendReceiveWip } from 'hooks/accounting/banking';
import { funcDefaultExRate } from 'pages/OceanFreight/Shipment/Billing/helpers';
import { subTotalTaxAmount } from 'pages/Service/common/helper';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel } from 'uikit';
import { TableUtils, UIUtils } from 'utils';

const FormBankingPaymentWip = ({
  excludeIds,
  source,
  params: { clientId, currencyId },
  onAddLastRow
}: {
  onAddLastRow: ({ params, remove }: { params: any[]; remove: any[] }) => void;
  source: BankingPaymentSource;
  params: {
    consolId?: string;
    serviceId?: string;
    shipmentId?: string;
    clientId: string;
    currencyId: number;
  };
  excludeIds: string[];
}) => {
  const isSpend = source === BankingPaymentSource.Spend;

  const [ids, setIds] = useState<number[]>([]);

  const {
    data: listBankingWip,
    refetch,
    isLoading
  } = useSpendReceiveWip({
    source,
    params: {
      excludeIds,
      clientId
    }
  });

  const columns = useMemo(() => {
    return [
      {
        label: trans('charge_code'),
        name: 'chargeCode.code'
      },
      {
        label: trans('description'),
        name: 'chargeCode.description'
      },
      {
        label: trans(isSpend ? 'ap_code' : 'ar_code'),
        name: isSpend ? 'apClient.code' : 'arClient.code'
      },
      {
        label: trans(isSpend ? 'ap_name' : 'ar_name'),
        name: isSpend ? 'apClient.name' : 'arClient.name',
        option: TableUtils.options.base3XLg
      },
      {
        label: trans('container_type_size'),
        name: 'containerTypeSize.name'
      },
      {
        label: trans('level_type'),
        name: 'chargeCode.levelType',
        options: {
          customBodyRender: (v: string) =>
            v ? (
              <KChip
                background={KColors.palette.blue.w50}
                brC={KColors.palette.blue.w600}
                label={trans(`option.${v.toLowerCase()}`)}
                textTransform={'uppercase'}
                color={KColors.palette.blue.w600}
                style={{ whiteSpace: 'nowrap' }}
              />
            ) : null
        }
      },
      {
        label: trans('calculator_type'),
        name: 'calculatorType',
        options: {
          customBodyRender: (v: string) =>
            v ? (
              <KChip
                background={KColors.palette.blue.w50}
                brC={KColors.palette.blue.w600}
                label={v}
                textTransform={'uppercase'}
                color={KColors.palette.blue.w600}
              />
            ) : null
        }
      },
      {
        label: trans('currency'),
        name: 'currency.code'
      },
      {
        label: trans('unit'),
        name: 'chargeCodeUnit.name'
      },
      {
        label: trans('unit_price_quantity'),
        name: 'wipPriceList',
        options: TableUtils.options.withMoreIcon({
          onPress: item => {
            UIUtils.popup.open({
              title: trans('unit_price_quantity'),
              maxWidth: 'xs',
              touchOutsideToDismiss: true,
              content: () => (
                <>
                  {item?.map((v, idx) => {
                    const marginT = idx === 0 ? 0 : '0.75rem';
                    return (
                      <KContainer.View
                        key={`charge-code-level-list-popup-${idx}`}
                        dp="flex"
                        center
                        paddingV="0.25rem"
                        paddingH="0.75rem"
                        br="x"
                        brW={1}
                        brC={KColors.warning.normal}
                        marginT={marginT}
                      >
                        <KLabel.Text typo="TextMdNormal">{v}</KLabel.Text>
                      </KContainer.View>
                    );
                  })}
                </>
              )
            });
          },
          renderItem: (i: any, idx) => {
            const marginL = idx === 0 ? 0 : '0.25rem';
            return (
              <KContainer.View
                key={`charge-code-level-list-popup-${idx}`}
                minW={83}
                height={20}
                marginL={marginL}
                dp="flex"
                center
                background={'#FFF5E5'}
                brC={KColors.warning.normal}
                br="2x"
                brW={1}
              >
                <KLabel.Text color={KColors.warning.dark}>{i}</KLabel.Text>
              </KContainer.View>
            );
          }
        })
      },
      {
        label: trans('est_sub_total'),
        name: 'estSubTotal',
        options: TableUtils.options.number()
      },
      {
        label: trans('tax'),
        name: 'taxType.code'
      },
      {
        label: trans('tax_amount'),
        name: 'taxAmount',
        options: TableUtils.options.number()
      },
      {
        label: trans('est_total_amount'),
        name: 'estTotal',
        options: TableUtils.options.number()
      },
      {
        label: trans('created_by'),
        name: 'createdUsername'
      },
      {
        label: trans('updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('created_at'),
        name: 'createdAt'
      },
      {
        label: trans('updated_at'),
        name: 'updatedAt'
      }
    ];
  }, [isSpend]);

  const table = useLocalTable({
    name: WebTable.BANKING_PAYMENT,
    columns,
    data: listBankingWip?.data ?? [],
    showTimeRange: true,
    onRefresh: refetch
  });

  useGlobalTable(table);

  const onAddBankingPayment = throttle(async () => {
    const rowSelectDetails = ids.reduce<any[]>((acc, curr) => {
      let rowSelect = table.data?.data[curr];
      return acc.concat(rowSelect);
    }, []);
    const params = rowSelectDetails.map(async item => {
      const { chargeCode } = item;
      const { taxType, taxTypeId } = subTotalTaxAmount({
        taxType: item?.taxType,
        _estSubTotal: 0
      });

      const defaultExRate = await funcDefaultExRate(
        chargeCode, // chargeCode
        currencyId,
        1
      );
      const { paymentSubTotal, paymentTaxAmount, paymentTotalAmount } =
        calcPayment({
          taxType: item?.taxType,
          estSubTotal: item?.estSubTotal,
          exchangeRate: defaultExRate
        });

      return {
        ...item,
        chartOfAccountId: !isSpend
          ? chargeCode.revenueAccountId
          : chargeCode.costAccountId,
        chartOfAccount: !isSpend
          ? chargeCode.revenueAccount
          : chargeCode.costAccount,
        wipId: item?.id,
        clientId: !isSpend ? item?.arClientId : item?.apClientId,
        client: !isSpend ? item?.arClient : item?.apClient,
        taxType,
        taxTypeId,
        exchangeRate: defaultExRate,
        paymentSubTotal,
        paymentTaxAmount,
        paymentTotalAmount,
        description: chargeCode?.description,
        subTotal: item?.estSubTotal
      };
    });

    onAddLastRow({
      params: await Promise.all(params),
      remove: []
    });
    UIUtils.popup.dismiss();
  }, 400);

  const newButton = useNewButton({
    title: trans('add'),
    marginR: '0.75rem',
    textColor: KColors.primary.normal,
    disabled: !ids.length,
    onPress: () => onAddBankingPayment()
  });

  return (
    <>
      <KContainer.Card marginT={'0.5rem'}>
        <DataTable
          {...table}
          isLoading={isLoading}
          options={{
            ...table.options,
            rowsSelected: ids,
            onRowSelectionChange: (_cur, _, rows) => {
              setIds(rows ?? []);
            }
          }}
        />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(FormBankingPaymentWip);
