/* eslint-disable max-lines */
export enum AgentType {
  Agent = 'AGE',
  Direct = 'DIR'
}

export enum ConsolType {
  Import = 'IMP',
  Export = 'EXP',
  Domestic = 'DOM'
}

export enum ImpConsolType {
  Import = 'IMP',
  Domestic = 'DOM'
}

export enum ExpConsolType {
  Export = 'EXP',
  Domestic = 'DOM'
}

export enum ShipmentType {
  Import = 'IMP',
  Export = 'EXP',
  Domestic = 'DOM'
}

export enum BrokerType {
  Import = 'IMP',
  Export = 'EXP'
}

export enum TermsType {
  Import = 'IMP',
  Export = 'EXP',
  Domestic = 'DOM',
  All = 'ALL'
}

export enum OceanContainerMode {
  FCL = 'FCL',
  LCL = 'LCL',
  FCX = 'FCX',
  FAK = 'FAK'
}

export enum OceanBasicContainerMode {
  FCL = 'FCL',
  LCL = 'LCL'
}

export enum AirContainerMode {
  ULD_FCL = 'ULD_FCL',
  LSE_LCL = 'LSE_LCL',
  ULD_FAK = 'ULD_FAK',
  BCN_FCX = 'BCN_FCX'
}

export enum AirBasicContainerMode {
  ULD_FCL = 'ULD_FCL',
  LSE_LCL = 'LSE_LCL'
}

export enum ContainerMode {
  FCL = 'FCL',
  LCL = 'LCL',
  FCX = 'FCX',
  FAK = 'FAK',
  ULD_FCL = 'ULD_FCL',
  LSE_LCL = 'LSE_LCL',
  ULD_FAK = 'ULD_FAK',
  BCN_FCX = 'BCN_FCX'
}

export enum ConsolStatusNew {
  Draft = 'DRF',
  Active = 'ACT'
}

export enum ConsolStatusDone {
  Complete = 'COM',
  Close = 'CLS'
}

export enum ConsolStatus {
  Draft = 'DRF',
  Active = 'ACT',
  PortOfLoading = 'POL',
  OnBoard = 'ONB',
  PortOfDischarge = 'POD',
  Complete = 'COM',
  Close = 'CLS'
}

export enum LegRoutingStatus {
  Pending = 'PEN',
  InProgress = 'PRO',
  Complete = 'COM'
}

export enum LegRoutingMode {
  Ocean = 'OCE',
  InLandTransit = 'ILT'
}

export enum LegRoutingModeAir {
  Air = 'AIR',
  InLandTransit = 'ILT'
}

export enum DeliveryMode {
  CY_CY = 'CY_CY',
  CY_CFS = 'CY_CFS',
  CY_DOOR = 'CY_DOOR',
  CFS_CFS = 'CFS_CFS',
  CFS_CY = 'CFS_CY',
  CFS_DOOR = 'CFS_DOOR',
  DOOR_DOOR = 'DOOR_DOOR',
  DOOR_CY = 'DOOR_CY',
  DOOR_CFS = 'DOOR_CFS'
}

export enum DeliveryModeAir {
  PORT_PORT = 'PORT_PORT',
  PORT_DOOR = 'PORT_DOOR',
  DOOR_PORT = 'DOOR_PORT',
  DOOR_DOOR = 'DOOR_DOOR'
}

export enum ShipmentStatus {
  Draft = 'DRF',
  Active = 'ACT',
  OriginInTransit = 'OIT',
  PortOfLoading = 'POL',
  InTransit = 'ITS',
  PortOfDischarge = 'POD',
  DestinationInTransit = 'DIT',
  Complete = 'COM',
  Close = 'CLS'
}

export enum ShipmentStatusWithoutOrigin {
  Draft = 'DRF',
  Active = 'ACT',
  PortOfLoading = 'POL'
}

export enum ShipmentStatusPodWithoutFinal {
  PortOfDischarge = 'POD',
  Complete = 'COM'
}

export enum ShipmentStatusCompleteWithoutFinal {
  PortOfDischarge = 'POD',
  Complete = 'COM',
  Close = 'CLS'
}

export enum ContainerStatus {
  Draft = 'DRF',
  Active = 'ACT',
  Loading = 'LOD',
  OnBoard = 'ONB',
  Discharge = 'DSC',
  Complete = 'COM'
}

export enum ContainerStatusNoConsol {
  Draft = 'DRF',
  Active = 'ACT',
  Loading = 'LOD'
}

// export enum ServiceContainerStatus {
//   Draft = 'DRF',
//   Active = 'ACT',
//   Complete = 'COM'
// }

// export enum ServiceContainerStatusDone {
//   Active = 'ACT',
//   Complete = 'COM'
// }

// export enum ServiceContainerStatusUnDone {
//   Draft = 'DRF',
//   Active = 'ACT'
// }

export enum ContainerStatusDone {
  Discharge = 'DSC',
  Complete = 'COM'
}

export enum ContainerStatusActiveLoading {
  Active = 'ACT',
  Loading = 'LOD'
}

export enum PenaltyAccountPayableType {
  Carrier = 'CAR',
  ContainerTerminalOperator = 'CTO',
  CoLoader = 'CLD',
  ContainerFreightStation = 'CFS'
}

export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum RelatedTab {
  Regular = 'regular',
  Ecus = 'ecus'
}

export enum StaffStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Leave = 'LEAVE'
}

export enum ResourceType {
  Avatar = 'AVATAR',
  Edoc = 'EDOC',
  Invoice = 'INVOICE',
  AccountingAdvice = 'ACCOUNTING_ADVICE',
  Issue = 'ISSUE'
}

export enum EmergencyRelationship {
  Spouse = 'SPOUSE',
  Mother = 'MOTHER',
  Father = 'FATHER',
  Child = 'CHILD',
  Other = 'OTHER'
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum WorkingType {
  InHouse = 'IN_HOUSE',
  SubContractor = 'SUB_CONTRACTOR'
}

export enum DimensionUnit {
  M = 'm',
  CM = 'cm',
  INCH = 'inch',
  KM = 'km',
  FEET = 'feet'
}

export enum VolumeUnit {
  M3 = 'm3',
  ML = 'ml',
  L = 'l'
}

export enum WeightUnit {
  KG = 'kg',
  G = 'g',
  TON = 'ton'
}

export enum TemperatureUnit {
  C = 'C',
  F = 'F'
}

export enum RepeatUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks'
}

export enum ClientKind {
  Corporate = 'Corporate',
  Personal = 'Personal'
}

export enum WorkingDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export enum CreditTerm {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER'
}

export enum CustomFilingType {
  AMS = 'AMS',
  ACI = 'ACI',
  AFR = 'AFR',
  ISF = 'ISF',
  ENS = 'ENS'
}

export enum AccountSettingType {
  Ar = 1,
  Ap = 2
}

export enum ArApType {
  Ar = 'AR',
  Ap = 'AP',
  ar = 'AR',
  ap = 'AP'
}

export enum AirOceanType {
  Ocean = 'OCEAN',
  Air = 'AIR'
}

export enum OriginDestinationType {
  Origin = 'ORIGIN',
  Destination = 'DESTINATION'
}

export enum CreditFrom {
  AtaAtd = 'ATA_ATD',
  ShipmentInTransit = 'SHIPMENT_IN_TRANSIT',
  ShipmentComplete = 'SHIPMENT_COMPLETE',
  InvoiceIssue = 'INVOICE_ISSUE'
}

export const AccountSettingInvoiceType = {
  DisbursementInvoice: 1,
  FreightInvoice: 2,
  LocalChargeInvoice: 3,
  ServiceInvoice: 4
};

export enum BaseCalculatorValue {
  CHARGE_CODE_UNIT = 'CHARGE_CODE_UNIT',
  SHIPMENT = 'SHIPMENT'
}

export enum BaseCalculatorPercent {
  NET_PROFIT_BEFORE_TAX = 'NET_PROFIT_BEFORE_TAX',
  NET_PROFIT_AFTER_TAX = 'NET_PROFIT_AFTER_TAX'
}

export enum BaseCalculator {
  CHARGE_CODE_UNIT = 'CHARGE_CODE_UNIT',
  SHIPMENT = 'SHIPMENT',
  NET_PROFIT_BEFORE_TAX = 'NET_PROFIT_BEFORE_TAX',
  NET_PROFIT_AFTER_TAX = 'NET_PROFIT_AFTER_TAX'
}

//discharge
export enum FreeDayImport {
  CTO_AVAILABLE = 'CTO_AVAILABLE',
  CFS_AVAILABLE = 'CFS_AVAILABLE',
  CTO_STORAGE = 'CTO_STORAGE',
  CFS_STORAGE = 'CFS_STORAGE',
  FCL_UNLOAD = 'FCL_UNLOAD',
  WHARF_GATE_OUT = 'WHARF_GATE_OUT',
  ESTIMATED_FULL_DELIVERY = 'ESTIMATED_FULL_DELIVERY',
  ACTUAL_FULL_DELIVERY = 'ACTUAL_FULL_DELIVERY',
  CY_GATE_IN = 'CY_GATE_IN',
  EMPTY_READY = 'EMPTY_READY'
}

// loading
export enum FreeDayExport {
  CTO_CUT_OFF = 'CTO_CUT_OFF',
  CFS_CUT_OFF = 'CFS_CUT_OFF',
  CTO_RECEIVAL = 'CTO_RECEIVAL',
  CFS_RECEIVAL = 'CFS_RECEIVAL',
  FCL_LOAD = 'FCL_LOAD',
  WHARF_GATEIN = 'WHARF_GATEIN',
  ESTIMATED_EMPTY_DELIVERY = 'ESTIMATED_EMPTY_DELIVERY',
  ACTUAL_EMPTY_DELIVERY = 'ACTUAL_EMPTY_DELIVERY',
  CY_GATE_OUT = 'CY_GATE_OUT',
  PICK_UP_FULL = 'PICK_UP_FULL'
}

export enum FreeDayImportExport {
  CTO_AVAILABLE = 'CTO_AVAILABLE',
  CFS_AVAILABLE = 'CFS_AVAILABLE',
  CTO_STORAGE = 'CTO_STORAGE',
  CFS_STORAGE = 'CFS_STORAGE',
  FCL_UNLOAD = 'FCL_UNLOAD',
  WHARF_GATE_OUT = 'WHARF_GATE_OUT',
  ESTIMATED_FULL_DELIVERY = 'ESTIMATED_FULL_DELIVERY',
  ACTUAL_FULL_DELIVERY = 'ACTUAL_FULL_DELIVERY',
  CY_GATE_OUT = 'CY_GATE_OUT',
  PICK_UP_FULL = 'PICK_UP_FULL',

  CTO_CUT_OFF = 'CTO_CUT_OFF',
  CFS_CUT_OFF = 'CFS_CUT_OFF',
  CTO_RECEIVAL = 'CTO_RECEIVAL',
  CFS_RECEIVAL = 'CFS_RECEIVAL',
  FCL_LOAD = 'FCL_LOAD',
  WHARF_GATEIN = 'WHARF_GATEIN',
  ESTIMATED_EMPTY_DELIVERY = 'ESTIMATED_EMPTY_DELIVERY',
  ACTUAL_EMPTY_DELIVERY = 'ACTUAL_EMPTY_DELIVERY',
  CY_GATE_IN = 'CY_GATE_IN',
  EMPTY_READY = 'EMPTY_READY'
}

export enum PaymentMode {
  Prepaid = 'PREPAID',
  Collect = 'COLLECT'
}

export enum ClientType {
  Agent = 'AGN',
  ForwarderAgent = 'FWD',
  CustomBroker = 'CUS',
  AccountReceivable = 'ACR',
  AccountPayable = 'ACP',
  NotifyParty = 'NTF',
  ShippingLine = 'SPL',
  Airline = 'AIL',
  InlandCarrier = 'ILC',
  CoLoader = 'CLO',
  Consignor = 'CNO',
  Consignee = 'CNE',
  AirPort = 'AIP',
  SeaPort = 'SEP',
  CFS = 'CFS',
  CTO = 'CTO',
  FTO = 'FTO',
  CY = 'CY',
  '3PLWarehouse' = 'WAH',
  ServiceProvider = 'SVP',
  AGENT = 'AGN'
}

export enum PickUpType {
  CONSIGNOR_NEE = 'CONSIGNOR_NEE',
  CFS = 'CFS',
  CTO = 'CTO',
  CY = 'CY',
  '3PLWarehouse' = 'WAH'
}

export enum TransportType {
  CY = 'CY',
  CONSIGNOR_NEE = 'CONSIGNOR_NEE',
  '3PLWarehouse' = 'WAH',
  CTO = 'CTO',
  CFS = 'CFS'
}

export enum CategoryType {
  Client = 'CLIENT',
  Freight = 'FREIGHT',
  Service = 'SERVICE',
  Accounting = 'ACCOUNTING',
  Sales = 'SALES',
  Staff = 'STAFF',
  ClientPortalUser = 'CLIENT_PORTAL_USER',
  Other = 'OTHER'
}

export enum SourceType {
  Client = 'CLIENT',
  Staff = 'STAFF',
  Consol = 'CONSOL',
  Shipment = 'SHIPMENT',
  Service = 'SERVICE',
  ShipmentWithOutConsol = 'SHIPMENT_WITH_OUT_CONSOL',
  ContainerConsol = 'CONTAINER_CONSOL',
  StatementOfAccount = 'STATEMENT_OF_ACCOUNT',
  PaymentAdvice = 'PAYMENT_ADVICE',
  ReceivableAdvice = 'RECEIVABLE_ADVICE',
  JobOrder = 'JOB_ORDER'
}

export const CategoryTypeMap = {
  [SourceType.Client]: {
    Client: 'CLIENT',
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Sales: 'SALES',
    Staff: 'STAFF',
    ClientPortalUser: 'CLIENT_PORTAL_USER',
    Other: 'OTHER'
  },
  [SourceType.Staff]: {
    Client: 'CLIENT',
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Sales: 'SALES',
    Staff: 'STAFF',
    ClientPortalUser: 'CLIENT_PORTAL_USER',
    Other: 'OTHER'
  },
  [SourceType.Consol]: {
    Client: 'CLIENT',
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Sales: 'SALES',
    SalesPerson: 'SALES_PERSON',
    Other: 'OTHER'
  },
  [SourceType.Shipment]: {
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Other: 'OTHER'
  },
  [SourceType.ShipmentWithOutConsol]: {
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Other: 'OTHER'
  },
  [SourceType.Service]: {
    Freight: 'FREIGHT',
    Accounting: 'ACCOUNTING',
    Other: 'OTHER'
  },
  [SourceType.JobOrder]: {
    Freight: 'FREIGHT',
    Service: 'SERVICE',
    Accounting: 'ACCOUNTING',
    Other: 'OTHER'
  }
};

export const SourceTypeMap = {
  [SourceType.Client]: {
    Client: 'CLIENT',
    ClientAddress: 'CLIENT_ADDRESS',
    ClientPortalUser: 'CLIENT_PORTAL_USER',
    ClientRelatedParty: 'CLIENT_RELATED_PARTY',
    ClientSalePerson: 'CLIENT_SALE_PERSON'
  },
  [SourceType.Staff]: {
    Staff: 'STAFF'
  },
  [SourceType.Consol]: {
    Consol: 'CONSOL',
    Shipment: 'SHIPMENT',
    ContainerConsol: 'CONTAINER_CONSOL',
    Client: 'CLIENT',
    Ap: 'AP',
    Ar: 'AR',
    Wip: 'WIP'
  },
  [SourceType.Shipment]: {
    Consol: 'CONSOL',
    Shipment: 'SHIPMENT',
    Container: 'CONTAINER',
    Client: 'CLIENT',
    ClientSalePerson: 'CLIENT_SALE_PERSON',
    Ap: 'AP',
    Ar: 'AR',
    Service: 'SERVICE',
    Wip: 'WIP'
  },
  [SourceType.ShipmentWithOutConsol]: {
    Shipment: 'SHIPMENT',
    Container: 'CONTAINER',
    Client: 'CLIENT',
    ClientSalePerson: 'CLIENT_SALE_PERSON',
    Ap: 'AP',
    Ar: 'AR',
    Service: 'SERVICE',
    Wip: 'WIP'
  },
  [SourceType.Service]: {
    Shipment: 'SHIPMENT',
    ServiceContainer: 'SERVICE_CONTAINER',
    Client: 'CLIENT',
    ClientSalePerson: 'CLIENT_SALE_PERSON',
    Ap: 'AP',
    Ar: 'AR',
    Service: 'SERVICE',
    Wip: 'WIP'
  },
  [SourceType.JobOrder]: {
    Consol: 'CONSOL',
    Shipment: 'SHIPMENT',
    Container: 'CONTAINER',
    Client: 'CLIENT',
    ClientSalePerson: 'CLIENT_SALE_PERSON',
    Ap: 'AP',
    Ar: 'AR',
    Service: 'SERVICE',
    Wip: 'WIP'
  }
};

export enum EdocStatus {
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

export enum EdocStatusEnable {
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Active = 'ACTIVE'
}

export enum TemplateBusinessType {
  Email = 'EMAIL',
  Alert = 'ALERT'
}

export enum LevelType {
  LevelType1 = 'LEVEL_TYPE1',
  LevelType2 = 'LEVEL_TYPE2'
}

export enum LevelMarkup {
  MarkupLevel1 = 'MARKUP_LEVEL_1',
  MarkupLevel2 = 'MARKUP_LEVEL_2',
  MarkupLevel3 = 'MARKUP_LEVEL_3'
}

export enum CalculatorType {
  Value = 'VALUE',
  Composite = 'COMPOSITE',
  Descriptive = 'DESCRIPTIVE'
}

export enum WipType {
  Value = 'VALUE',
  Percentage = 'PERCENTAGE'
}

export enum MarkupType {
  Value = 'VALUE',
  Percentage = 'PERCENTAGE'
}

// --------- InvoiceTypeNotCreditNote clone từ InvoiceType -----------
// Những chỗ đang dùng InvoiceTypeNotCreditNote để show các option. vẫn có thể dùng InvoiceType.SERVICE or InvoiceType.LOCAL_CHARGE đề được
export enum InvoiceType {
  CREDIT_NOTE = 'CREDIT_NOTE',
  ORDINARY = 'ORDINARY',
  SERVICE = 'SERVICE',
  OBH = 'OBH',
  PRE_SOA = 'PRE_SOA',
  FREIGHT = 'FREIGHT'
}

export enum InvoiceTypeNotCreditNote {
  ORDINARY = 'ORDINARY',
  OBH = 'OBH', // này là OBH
  PRE_SOA = 'PRE_SOA'
}

export enum InvoiceTypeNotCreditNote2 {
  ORDINARY = 'ORDINARY',
  PRE_SOA = 'PRE_SOA'
}

// --------- InvoiceTypeNotCreditNote clone từ InvoiceType -----------

export enum PaymentStatus {
  UNPAID = 'UNPAID',
  PARTLY_PAID = 'PARTLY_PAID',
  PAID = 'PAID'
}

export enum APInvoiceStatus {
  DRAFT = 'DRAFT',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  VERIFIED = 'VERIFIED',
  APPROVED = 'APPROVED',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL'
}

export enum ARInvoiceStatus {
  DRAFT = 'DRAFT',
  CONFIRM_REQUEST = 'CONFIRM_REQUEST',
  CONFIRMED = 'CONFIRMED',
  INVOICE_REQUEST = 'INVOICE_REQUEST',
  ISSUED = 'ISSUED',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL'
}

export enum JobOrderStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  CANCEL = 'CANCEL'
}

export enum PicStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED'
}

export enum SystemChargeCodeUnit {
  CON = 'CON',
  DAY = 'DAY',
  SET = 'SET',
  SHI = 'SHI',
  M3 = 'M3',
  BOL = 'BOL',
  KG = 'KG'
}

export enum WipServiceType {
  Origin = 'ORIGIN',
  Destination = 'DESTINATION',
  Freight = 'FREIGHT'
}

export enum ExchangeRateType {
  ATA_ATD = 'ATA_ATD',
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum ExchangeRateTypeALL {
  ETA = 'ETA',
  ETD = 'ETD',
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum IDueDateType {
  ATA = 'ATA',
  ATD = 'ATD'
}

export enum ExchangeRateTypeImport {
  ETA = 'ETA',
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum ExchangeRateTypeExport {
  ETD = 'ETD',
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum InvoiceDate {
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum TransportStatus {
  Draft = 'DRF',
  Active = 'ACT',
  Complete = 'COM',
  Close = 'CLS'
}

export enum InvoiceCategory {
  Accounting = 'accounting',
  Billing = 'billing',
  Service = 'service',
  JobOrder = 'job-order'
}

export enum JobOrderCategory {
  Costing_Rate = 'costing-rate',
  Selling_Rate = 'selling-rate',
  Send_Review_Request = 'send-review-request',
  Pic_Response_Request = 'pic-response-request'
}

export enum ServiceStatus {
  Draft = 'DRF',
  Active = 'ACT',
  Complete = 'COM',
  Close = 'CLS'
}

export enum ServiceType {
  Transport = 'TRANSPORT',
  Handling = 'HANDLING',
  Customs = 'CUSTOMS'
}

export enum WIP {
  Transport = 'TRANSPORT',
  Shipment = 'SHIPMENT',
  Customs = 'CUSTOMS'
}

export enum TransportRoutingStatus {
  PEN = 'PEN',
  PRO = 'PRO',
  COM = 'COM'
}

export enum StatementInvoiceType {
  RECEIVABLE = 'RECEIVABLE',
  PAYABLE = 'PAYABLE',
  RECEIVABLE_PAYABLE = 'RECEIVABLE_PAYABLE'
}

export enum SOADateType {
  ISSUED_DATE = 'ISSUED_DATE',
  CREATED_AT = 'CREATED_AT'
}

//-----------------------GroupPermissions----------------------------

export enum GroupClient {
  CLIENT = 'CLIENT',
  CLIENT_DETAIL = 'CLIENT_DETAIL',
  CLIENT_ADDRESS = 'CLIENT_ADDRESS',
  CLIENT_PORTAL_USER = 'CLIENT_PORTAL_USER',
  CLIENT_AR_ACCOUNT_SETTING = 'CLIENT_AR_ACCOUNT_SETTING',
  CLIENT_AR_CURRENCY_UPLIFT = 'CLIENT_AR_CURRENCY_UPLIFT',
  CLIENT_AR_BANK_ACCOUNT = 'CLIENT_AR_BANK_ACCOUNT',
  CLIENT_AP_ACCOUNT_SETTING = 'CLIENT_AP_ACCOUNT_SETTING',
  CLIENT_AP_BANK_ACCOUNT = 'CLIENT_AP_BANK_ACCOUNT',
  CLIENT_RELATED_PARTY = 'CLIENT_RELATED_PARTY',
  CLIENT_SALE_PERSON = 'CLIENT_SALE_PERSON',
  CLIENT_ALERT_NOTE = 'CLIENT_ALERT_NOTE',
  CLIENT_DOCUMENT = 'CLIENT_DOCUMENT',
  CLIENT_LOG = 'CLIENT_LOG'
}

export enum GroupOceanFreight {
  OCEAN_FREIGHT = 'OCEAN_FREIGHT',
  OCEAN_CONSOL_DETAIL = 'OCEAN_CONSOL_DETAIL',
  OCEAN_CONSOL_ROUTING = 'OCEAN_CONSOL_ROUTING',
  OCEAN_CONSOL_CONTAINER = 'OCEAN_CONSOL_CONTAINER',
  OCEAN_CONSOL_FAK_TRANSPORT = 'OCEAN_CONSOL_FAK_TRANSPORT',
  // OCEAN_CONSOL_ACCOUNTING_DETAIL = 'OCEAN_CONSOL_ACCOUNTING_DETAIL',
  OCEAN_CONSOL_ACCOUNTING_SUMMARY = 'OCEAN_CONSOL_ACCOUNTING_SUMMARY',
  OCEAN_CONSOL_WIP = 'OCEAN_CONSOL_WIP',
  OCEAN_CONSOL_AR_INVOICE = 'OCEAN_CONSOL_AR_INVOICE',
  OCEAN_CONSOL_AP_INVOICE = 'OCEAN_CONSOL_AP_INVOICE',
  OCEAN_CONSOL_CHARGE_CODE = 'OCEAN_CONSOL_CHARGE_CODE',
  OCEAN_CONSOL_ALERT_NOTE = 'OCEAN_CONSOL_ALERT_NOTE',
  OCEAN_CONSOL_DOCUMENT = 'OCEAN_CONSOL_DOCUMENT',
  OCEAN_CONSOL_LOG = 'OCEAN_CONSOL_LOG',

  OCEAN_SHIPMENT_DETAIL = 'OCEAN_SHIPMENT_DETAIL',
  OCEAN_SHIPMENT_ROUTING = 'OCEAN_SHIPMENT_ROUTING',
  OCEAN_SHIPMENT_CONTAINER = 'OCEAN_SHIPMENT_CONTAINER',
  OCEAN_SHIPMENT_SERVICE = 'OCEAN_SHIPMENT_SERVICE',
  OCEAN_SHIPMENT_BILLING_SUMMARY = 'OCEAN_SHIPMENT_BILLING_SUMMARY',
  // OCEAN_SHIPMENT_BILLING_DETAIL = 'OCEAN_SHIPMENT_BILLING_DETAIL',
  OCEAN_SHIPMENT_WIP = 'OCEAN_SHIPMENT_WIP',
  OCEAN_SHIPMENT_AR_INVOICE = 'OCEAN_SHIPMENT_AR_INVOICE',
  OCEAN_SHIPMENT_AP_INVOICE = 'OCEAN_SHIPMENT_AP_INVOICE',
  OCEAN_SHIPMENT_CHARGE_CODE = 'OCEAN_SHIPMENT_CHARGE_CODE',
  OCEAN_SHIPMENT_ALERT_NOTE = 'OCEAN_SHIPMENT_ALERT_NOTE',
  OCEAN_SHIPMENT_DOCUMENT = 'OCEAN_SHIPMENT_DOCUMENT',
  OCEAN_SHIPMENT_LOG = 'OCEAN_SHIPMENT_LOG'
}

export enum GroupAirFreight {
  AIR_FREIGHT = 'AIR_FREIGHT',
  AIR_CONSOL_DETAIL = 'AIR_CONSOL_DETAIL',
  AIR_CONSOL_ROUTING = 'AIR_CONSOL_ROUTING',
  AIR_CONSOL_CONTAINER = 'AIR_CONSOL_CONTAINER',
  AIR_CONSOL_FAK_TRANSPORT = 'AIR_CONSOL_FAK_TRANSPORT',
  AIR_CONSOL_ACCOUNTING_SUMMARY = 'AIR_CONSOL_ACCOUNTING_SUMMARY',
  // AIR_CONSOL_ACCOUNTING_DETAIL = 'AIR_CONSOL_ACCOUNTING_DETAIL',
  AIR_CONSOL_WIP = 'AIR_CONSOL_WIP',
  AIR_CONSOL_AR_INVOICE = 'AIR_CONSOL_AR_INVOICE',
  AIR_CONSOL_AP_INVOICE = 'AIR_CONSOL_AP_INVOICE',
  AIR_CONSOL_CHARGE_CODE = 'AIR_CONSOL_CHARGE_CODE',
  AIR_CONSOL_ALERT_NOTE = 'AIR_CONSOL_ALERT_NOTE',
  AIR_CONSOL_DOCUMENT = 'AIR_CONSOL_DOCUMENT',
  AIR_CONSOL_LOG = 'AIR_CONSOL_LOG',

  AIR_SHIPMENT_DETAIL = 'AIR_SHIPMENT_DETAIL',
  AIR_SHIPMENT_ROUTING = 'AIR_SHIPMENT_ROUTING',
  AIR_SHIPMENT_CONTAINER = 'AIR_SHIPMENT_CONTAINER',
  AIR_SHIPMENT_SERVICE = 'AIR_SHIPMENT_SERVICE',
  AIR_SHIPMENT_BILLING_SUMMARY = 'AIR_SHIPMENT_BILLING_SUMMARY',
  // AIR_SHIPMENT_BILLING_DETAIL = 'AIR_SHIPMENT_BILLING_DETAIL',
  AIR_SHIPMENT_WIP = 'AIR_SHIPMENT_WIP',
  AIR_SHIPMENT_AR_INVOICE = 'AIR_SHIPMENT_AR_INVOICE',
  AIR_SHIPMENT_AP_INVOICE = 'AIR_SHIPMENT_AP_INVOICE',
  AIR_SHIPMENT_CHARGE_CODE = 'AIR_SHIPMENT_CHARGE_CODE',
  AIR_SHIPMENT_ALERT_NOTE = 'AIR_SHIPMENT_ALERT_NOTE',
  AIR_SHIPMENT_DOCUMENT = 'AIR_SHIPMENT_DOCUMENT',
  AIR_SHIPMENT_LOG = 'AIR_SHIPMENT_LOG'
}

export enum GroupAccounting {
  ACCOUNTING = 'ACCOUNTING',
  ACCOUNTING_DASHBOARD = 'ACCOUNTING_DASHBOARD',
  ACCOUNTING_AR_INVOICE = 'ACCOUNTING_AR_INVOICE',
  ACCOUNTING_RECEIVABLE_ADVICE = 'ACCOUNTING_RECEIVABLE_ADVICE',
  ACCOUNTING_AR_INVOICE_SUMMARY = 'ACCOUNTING_AR_INVOICE_SUMMARY',
  ACCOUNTING_AP_INVOICE = 'ACCOUNTING_AP_INVOICE',
  ACCOUNTING_PAYMENT_ADVICE = 'ACCOUNTING_PAYMENT_ADVICE',
  ACCOUNTING_BANK_ACCOUNT = 'ACCOUNTING_BANK_ACCOUNT',
  ACCOUNTING_CHART_OF_ACCOUNT = 'ACCOUNTING_CHART_OF_ACCOUNT',
  ACCOUNTING_LINKED_ACCOUNT = 'ACCOUNTING_LINKED_ACCOUNT',
  ACCOUNTING_TAX_CODE = 'ACCOUNTING_TAX_CODE'
}

export enum GroupLibrary {
  LIBRARY = 'LIBRARY',
  LIBRARY_EXCHANGE_RATE = 'LIBRARY_EXCHANGE_RATE',
  LIBRARY_SETTING = 'LIBRARY_SETTING'
}

export enum GroupSummary {
  ACCOUNTING = 'ACCOUNTING',
  AR_INVOICE = 'AR_INVOICE',
  AP_INVOICE = 'AP_INVOICE',
  AR_CLIENT = 'AR_CLIENT',
  AP_CLIENT = 'AP_CLIENT',
  RECEIVABLE_ADVICE = 'RECEIVABLE_ADVICE',
  PAYMENT_ADVICE = 'PAYMENT_ADVICE',
  STATEMENT_OF_ACCOUNTING = 'STATEMENT_OF_ACCOUNTING',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_TRANSACTION = 'BANK_TRANSACTION',
  BANK_RECONCILE_TRANSACTION = 'BANK_RECONCILE_TRANSACTION'
}

export enum GroupDashboard {
  DASHBOARD_OPERATION = 'DASHBOARD_OPERATION'
}

export enum GroupOther {
  ADMIN = 'ADMIN',
  SYSTEM_SETTING = 'SYSTEM_SETTING'
}

export enum GroupServiceCustom {
  SERVICE_CUSTOM = 'SERVICE_CUSTOM',
  SERVICE_CUSTOM_DETAIL = 'SERVICE_CUSTOM_DETAIL',
  SERVICE_CUSTOM_COMMERCIAL_INVOICE = 'SERVICE_CUSTOM_COMMERCIAL_INVOICE',
  SERVICE_CUSTOM_CONTAINER = 'SERVICE_CUSTOM_CONTAINER',
  SERVICE_CUSTOM_BILLING_SUMMARY = 'SERVICE_CUSTOM_BILLING_SUMMARY',
  SERVICE_CUSTOM_WIP = 'SERVICE_CUSTOM_WIP',
  SERVICE_CUSTOM_AR_INVOICE = 'SERVICE_CUSTOM_AR_INVOICE',
  SERVICE_CUSTOM_AP_INVOICE = 'SERVICE_CUSTOM_AP_INVOICE',
  SERVICE_CUSTOM_CHARGE_CODE = 'SERVICE_CUSTOM_CHARGE_CODE',
  SERVICE_CUSTOM_ALERT_NOTE = 'SERVICE_CUSTOM_ALERT_NOTE',
  SERVICE_CUSTOM_DOCUMENT = 'SERVICE_CUSTOM_DOCUMENT',
  SERVICE_CUSTOM_LOG = 'SERVICE_CUSTOM_LOG'
}

export enum GroupServiceTransport {
  SERVICE_TRANSPORT = 'SERVICE_TRANSPORT',
  SERVICE_TRANSPORT_DETAIL = 'SERVICE_TRANSPORT_DETAIL',
  SERVICE_TRANSPORT_ROUTING = 'SERVICE_TRANSPORT_ROUTING',
  SERVICE_TRANSPORT_CONTAINER = 'SERVICE_TRANSPORT_CONTAINER',
  SERVICE_TRANSPORT_BILLING_SUMMARY = 'SERVICE_TRANSPORT_BILLING_SUMMARY',
  SERVICE_TRANSPORT_WIP = 'SERVICE_TRANSPORT_WIP',
  SERVICE_TRANSPORT_AR_INVOICE = 'SERVICE_TRANSPORT_AR_INVOICE',
  SERVICE_TRANSPORT_AP_INVOICE = 'SERVICE_TRANSPORT_AP_INVOICE',
  SERVICE_TRANSPORT_CHARGE_CODE = 'SERVICE_TRANSPORT_CHARGE_CODE',
  SERVICE_TRANSPORT_ALERT_NOTE = 'SERVICE_TRANSPORT_ALERT_NOTE',
  SERVICE_TRANSPORT_DOCUMENT = 'SERVICE_TRANSPORT_DOCUMENT',
  SERVICE_TRANSPORT_LOG = 'SERVICE_TRANSPORT_LOG'
}

export enum GroupServiceHandling {
  SERVICE_HANDLING = 'SERVICE_HANDLING',
  SERVICE_HANDLING_DETAIL = 'SERVICE_HANDLING_DETAIL',
  SERVICE_HANDLING_SERVICE = 'SERVICE_HANDLING_SERVICE',
  SERVICE_HANDLING_CONTAINER = 'SERVICE_HANDLING_CONTAINER',
  SERVICE_HANDLING_BILLING_SUMMARY = 'SERVICE_HANDLING_BILLING_SUMMARY',
  SERVICE_HANDLING_WIP = 'SERVICE_HANDLING_WIP',
  SERVICE_HANDLING_AR_INVOICE = 'SERVICE_HANDLING_AR_INVOICE',
  SERVICE_HANDLING_AP_INVOICE = 'SERVICE_HANDLING_AP_INVOICE',
  SERVICE_HANDLING_CHARGE_CODE = 'SERVICE_HANDLING_CHARGE_CODE',
  SERVICE_HANDLING_ALERT_NOTE = 'SERVICE_HANDLING_ALERT_NOTE',
  SERVICE_HANDLING_DOCUMENT = 'SERVICE_HANDLING_DOCUMENT',
  SERVICE_HANDLING_LOG = 'SERVICE_HANDLING_LOG'
}

//---------------------------------------------------

export enum ReconcileStatus {
  UNDONE = 'UNDONE',
  DONE = 'DONE'
}

export enum TypeAdjustment {
  BANK_FEE = 'BANK_FEE',
  MINOR_ADJUSTMENT = 'MINOR_ADJUSTMENT'
}

export enum ReconcileAdviceStatus {
  UNRECONCILED = 'UNRECONCILED',
  RECONCILED = 'RECONCILED'
}

export enum AdviceStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Complete = 'COMPLETE'
}

export enum SyncStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export enum SyncToMisa {
  Internal = 'INTERNAL'
}

export enum NoteToCLient {
  NoteToCLient = 'NOTE_TO_CLIENT'
}

export enum AccountLevel {
  Level1 = 'LEVEL_1',
  Level2 = 'LEVEL_2',
  Level3 = 'LEVEL_3'
}

interface QuantityData {
  chargeCodeUnit: any;
  totalQuantityCal: number | null;
  clientQuantity: number | null;
  chargeableQuantity: number | null;
}

export enum DataCalKey {
  BOL = 'BOL',
  CON = 'CON',
  KG = 'KG',
  M3 = 'M3'
}

export type DataCal = Record<keyof DataCalKey, QuantityData>;

export enum LinkedAccountType {
  Sales = 'SALE',
  Purchases = 'PURCHASE',
  AccountBanking = 'ACCOUNT_BANKING'
}

export enum LinkedAccountCode {
  SalesAssetVND = 'ASSET_ACCOUNT_FOR_RECEIVABLE_VND',
  SalesAssetUSD = 'ASSET_ACCOUNT_FOR_RECEIVABLE_USD',

  SalesBankVND = 'BANK_ACCOUNT_FOR_CUSTOMER_VND',
  SalesBankUSD = 'BANK_ACCOUNT_FOR_CUSTOMER_USD',

  PurchasesAssetVND = 'LIABILITY_ACCOUNT_FOR_PAYABLE_VND',
  PurchasesAssetUSD = 'LIABILITY_ACCOUNT_FOR_PAYABLE_USD',

  PurchasesBankVND = 'BANK_ACCOUNT_FOR_PAYING_BILLS_VND',
  PurchasesBankUSD = 'BANK_ACCOUNT_FOR_PAYING_BILLS_USD',

  SalesAssetOBH = 'ASSET_ACCOUNT_FOR_TRACKING_RECEIVES_OBH',
  PurchasesAssetOBH = 'ASSET_ACCOUNT_FOR_TRACKING_PAYABLE_OBH'
}

export enum UIDataType {
  Cs = 'CONSOL',
  Sm = 'SHIPMENT',
  Sv = 'SERVICE',
  Iv = 'INVOICE'
}

export enum AdviceType {
  Receivable = 'RECEIVABLE_ADVICE',
  Payment = 'PAYMENT_ADVICE'
}

export enum DeclarationFormType {
  Red = 'RED',
  Green = 'GREEN',
  Yellow = 'YELLOW'
}

export enum SyncMisa {
  Ar = 'sync-ar-invoice',
  Ap = 'sync-ap-invoice',
  payment = 'sync-payment-advice',
  receivable = 'sync-receivable-advice'
}

export enum IssueBrowser {
  Chrome = 'Chrome',
  Edge = 'Edge'
}

export enum IssueType {
  Bug = 'BUG',
  How_To_Use = 'HOW_TO_USE',
  Additional_Request = 'ADDITIONAL_REQUEST'
}

export enum IssuePlatform {
  Windows = 'WINDOWS',
  Macos = 'MACOS',
  Android = 'ANDROID',
  IOS = 'iOS'
}

export enum IssueStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
  Archived = 'ARCHIVED'
}

export enum IssueUrgencyLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export const keyConsolType = {
  [ConsolType.Import]: 'isImport',
  [ConsolType.Export]: 'isExport',
  [ConsolType.Domestic]: 'isDomestic'
};

export enum EventName {
  // Event
  LOGOUT = 'LOGOUT',

  // Event Source
  PING = 'PING_EVENT',
  COUNT_UNREAD = 'COUNT_UNREAD_EVENT'
}

export enum InvoiceTemplateEnum {
  InvoiceAr = 'invoice_ar',
  InvoiceAp = 'invoice_ap'
}

export enum StatementOfAccountStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PAID = 'PAID',
  PARTLY_PAID = 'PARTLY_PAID',
  CANCEL = 'CANCEL'
}

export enum PaymentType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}

export enum StatementPaymentStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  CANCEL = 'CANCEL'
}

export enum StatementInvoiceStatus {
  DRAFT = 'DRAFT',
  ISSUED = 'ISSUED',
  CANCEL = 'CANCEL'
}

export enum TTypeAdvice {
  receivableAdvice = 'receivable-advice',
  paymentAdvice = 'payment-advice'
}

export enum StatementType {
  AR_SOA_INVOICE = 'DEBIT',
  AP_SOA_INVOICE = 'CREDIT'
}

export enum SpendReceiveStatus {
  DRAFT = 'DRAFT',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  ACTIVE = 'ACTIVE',
  CANCEL = 'CANCEL'
}

export enum ReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export enum ClientGroup {
  'CL' = 'VENDOR',
  'AG' = 'AGENT',
  'CS' = 'CUSTOMER',
  'OC' = 'OTHER_CLIENT'
}

export enum PicType {
  MAIN = 'MAIN',
  CUSTOMS = 'CUSTOMS',
  TRANSPORT = 'TRANSPORT',
  HANDLING = 'HANDLING',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE'
}

export enum UITab {
  // Client
  Client = 'CLIENT_DETAIL',
  ClientAddress = 'CLIENT_DETAIL_ADDRESS',
  ClientAR = 'CLIENT_DETAIL_AR',
  ClientAP = 'CLIENT_DETAIL_AP',
  ClientRelatedParties = 'CLIENT_DETAIL_RELATED_PARTIES',
  ClientSalePersons = 'CLIENT_DETAIL_SALE_PERSONS',
  ClientAlertNote = 'CLIENT_DETAIL_ALERT_NOTE',
  ClientDocument = 'CLIENT_DETAIL_DOCUMENT',
  ClientLog = 'CLIENT_DETAIL_LOG',

  // Ocean Freight - Consolidation
  OFConsol = 'OF_CONSOL_DETAIL',
  OFConsolRouting = 'OF_CONSOL_DETAIL_ROUTING',
  OFConsolTransport = 'OF_CONSOL_DETAIL_TRANSPORT',
  OFConsolShipment = 'OF_CONSOL_DETAIL_SHIPMENT',
  OFConsolContainers = 'OF_CONSOL_DETAIL_CONTAINERS',
  OFConsolWip = 'OF_CONSOL_DETAIL_WIP',
  OFConsolAccounting = 'OF_CONSOL_DETAIL_ACCOUNTING',
  OFConsolAlertNote = 'OF_CONSOL_DETAIL_ALERT_NOTE',
  OFConsolDocument = 'OF_CONSOL_DETAIL_DOCUMENT',
  OFConsolAccountingInvoice = 'OF_CONSOL_DETAIL_ACCOUNTING_INVOICE',
  OFConsolAccountingChargeCode = 'OF_CONSOL_DETAIL_ACCOUNTING_CHARGE_CODE',

  // Ocean Freight - Shipment
  OFShipment = 'OF_SHIPMENT_DETAIL',
  OFShipmentRouting = 'OF_SHIPMENT_DETAIL_ROUTING',
  OFShipmentService = 'OF_SHIPMENT_DETAIL_SERVICE',
  OFShipmentContainers = 'OF_SHIPMENT_DETAIL_CONTAINERS',
  OFShipmentWip = 'OF_SHIPMENT_DETAIL_WIP',
  OFShipmentBilling = 'OF_SHIPMENT_DETAIL_BILLING',
  OFShipmentAlertNote = 'OF_SHIPMENT_DETAIL_ALERT_NOTE',
  OFShipmentDocument = 'OF_SHIPMENT_DETAIL_DOCUMENT',
  OFShipmentBillingInvoice = 'OF_SHIPMENT_DETAIL_BILLING_INVOICE',
  OFShipmentBillingChargeCode = 'OF_SHIPMENT_DETAIL_BILLING_CHARGE_CODE',

  // Air Freight - Consolidation
  AFConsol = 'AF_CONSOL_DETAIL',
  AFConsolRouting = 'AF_CONSOL_DETAIL_ROUTING',
  AFConsolTransport = 'AF_CONSOL_DETAIL_TRANSPORT',
  AFConsolShipment = 'AF_CONSOL_DETAIL_SHIPMENT',
  AFConsolContainers = 'AF_CONSOL_DETAIL_CONTAINERS',
  AFConsolWip = 'AF_CONSOL_DETAIL_WIP',
  AFConsolAccounting = 'AF_CONSOL_DETAIL_ACCOUNTING',
  AFConsolAlertNote = 'AF_CONSOL_DETAIL_ALERT_NOTE',
  AFConsolDocument = 'AF_CONSOL_DETAIL_DOCUMENT',
  AFConsolAccountingInvoice = 'AF_CONSOL_DETAIL_ACCOUNTING_INVOICE',
  AFConsolAccountingChargeCode = 'AF_CONSOL_DETAIL_ACCOUNTING_CHARGE_CODE',

  // Air Freight - Shipment
  AFShipment = 'AF_SHIPMENT_DETAIL',
  AFShipmentRouting = 'AF_SHIPMENT_DETAIL_ROUTING',
  AFShipmentService = 'AF_SHIPMENT_DETAIL_SERVICE',
  AFShipmentContainers = 'AF_SHIPMENT_DETAIL_CONTAINERS',
  AFShipmentWip = 'AF_SHIPMENT_DETAIL_WIP',
  AFShipmentBilling = 'AF_SHIPMENT_DETAIL_BILLING',
  AFShipmentAlertNote = 'AF_SHIPMENT_DETAIL_ALERT_NOTE',
  AFShipmentDocument = 'AF_SHIPMENT_DETAIL_DOCUMENT',
  AFShipmentBillingInvoice = 'AF_SHIPMENT_DETAIL_BILLING_INVOICE',
  AFShipmentBillingChargeCode = 'AF_SHIPMENT_DETAIL_BILLING_CHARGE_CODE',

  // Service
  Service = 'SERVICE_DETAIL',
  ServiceCustoms = 'SERVICE_DETAIL_CUSTOMS',
  ServiceTransport = 'SERVICE_DETAIL_TRANSPORT',
  ServiceContainers = 'SERVICE_DETAIL_CONTAINERS',
  ServiceBilling = 'SERVICE_DETAIL_BILLING',
  ServiceAlertNote = 'SERVICE_DETAIL_ALERT_NOTE',
  ServiceDocument = 'SERVICE_DETAIL_DOCUMENT',
  ServiceBillingInvoice = 'SERVICE_DETAIL_BILLING_INVOICE',
  ServiceBillingChargeCode = 'SERVICE_DETAIL_BILLING_CHARGE_CODE',

  // Service - Customs
  Customs = 'CUSTOMS_DETAIL',
  CustomsCommercialInvoice = 'CUSTOMS_COMMERCIAL_INVOICE',
  CustomsWip = 'CUSTOMS_WIP',

  // Service - Transport
  Transport = 'TRANSPORT_DETAIL',
  TransportWip = 'TRANSPORT_WIP',

  // Accounting
  Accounting = 'ACCOUNTING_DASHBOARD',
  AccountingSales = 'ACCOUNTING_SALES',
  AccountingPurchases = 'ACCOUNTING_PURCHASES',
  AccountingStatement = 'ACCOUNTING_STATEMENT',
  AccountingBanking = 'ACCOUNTING_BANKING',
  AccountingMore = 'ACCOUNTING_MORE',

  // Accounting - Banking
  BankingBankAccounts = 'BANKING_BANK_ACCOUNTS',
  BankingBankTransactions = 'BANKING_BANK_TRANSACTIONS',
  BankingReceiveSpendMoney = 'BANKING_RECEIVE_SPEND_MONEY',

  // Accounting - More
  MoreChartOfAccounts = 'MORE_CHART_OF_ACCOUNTS',
  MoreManageLinkedAccount = 'MORE_MANAGE_LINKED_ACCOUNT',
  MoreTaxCodes = 'MORE_TAX_CODES',

  // Accounting - Sales
  SalesARInvoices = 'SALES_AR_INVOICES',
  SalesReceivableAdvice = 'SALES_RECEIVABLE_ADVICE',

  // Accounting - Purchases
  PurchasesAPInvoices = 'PURCHASES_AP_INVOICES',
  PurchasesPaymentAdvice = 'PURCHASES_PAYMENT_ADVICE',

  // Accounting - Statement
  StatementOfAccount = 'STATEMENT_OF_ACCOUNT',
  StatementPayment = 'STATEMENT_PAYMENT',
  StatementInvoice = 'STATEMENT_INVOICE',

  // Report
  ReportExport = 'REPORT_EXPORT',
  ReportImport = 'REPORT_IMPORT',
  ReportLogistics = 'REPORT_LOGISTICS',
  ReportSales = 'REPORT_SALES',
  ReportCustomerService = 'REPORT_CUSTOMER_SERVICE',
  ReportMarketing = 'REPORT_MARKETING',

  // Category
  CategoryClient = 'CATEGORY_CLIENT',
  CategoryFreight = 'CATEGORY_FREIGHT',
  CategoryService = 'CATEGORY_SERVICE',
  CategoryAccounting = 'CATEGORY_ACCOUNTING',
  CategorySalesMarketing = 'CATEGORY_SALES_MARKETING',
  CategoryAdministration = 'CATEGORY_ADMINISTRATION',
  CategoryEcus = 'CATEGORY_ECUS',
  CategoryOther = 'CATEGORY_OTHER',

  // Job Order
  JobOrder = 'JOB_ORDER_DETAIL',
  JobOrderRate = 'JOB_ORDER_DETAIL_RATE',
  JobOrderWip = 'JOB_ORDER_DETAIL_WIP',
  JobOrderAlertNote = 'JOB_ORDER_DETAIL_ALERT_NOTE',
  JobOrderDocument = 'JOB_ORDER_DETAIL_DOCUMENT',
  JobOrderLog = 'JOB_ORDER_DETAIL_LOG'
}

export const Report = {
  // LOGISTICS
  monthly_customs_report: 'MONTHLY_CUSTOMS_REPORT',
  monthly_transport_report: 'MONTHLY_TRANSPORT_REPORT',
  // IMPORT EXPORT
  monthly_volume: 'MONTHLY_VOLUME'
};

export const DateType = {
  [ExchangeRateTypeALL.ETD]: ExchangeRateTypeALL.ETD,
  [ExchangeRateTypeALL.ETA]: ExchangeRateTypeALL.ETA,
  ETD_ETA: 'ETD_ETA'
};
export const DateTypeLogistics = DateType;
export const ReportLogistics = {
  [Report.monthly_customs_report]: Report.monthly_customs_report,
  [Report.monthly_transport_report]: Report.monthly_transport_report
};

export enum BankingPaymentSource {
  Spend = 'SPEND',
  Receive = 'RECEIVE'
}

export enum BankingPaymentFrom {
  FromWip = 'FROM_WIP',
  WithoutJob = 'WITHOUT_JOB'
}

export enum NotificationLogSourceType {
  SYSTEM = 'SYSTEM',
  JOB_ORDER = 'JOB_ORDER',
  SERVICE = 'SERVICE',
  SHIPMENT = 'SHIPMENT',
  AP_INVOICE = 'AP_INVOICE',
  AR_INVOICE = 'AR_INVOICE'
}
