import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  APInvoiceStatus,
  ArApType,
  ARInvoiceStatus,
  ENDPOINTS,
  InvoiceCategory,
  QUERY_KEYS,
  tableRef,
  WebTable
} from '@constants';
import APIManager from '@services';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchApInvoice2 = (
  category: InvoiceCategory,
  id?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.apInvoice(category, ':id', {
          id
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchArInvoice2 = (
  category: InvoiceCategory,
  id?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.arInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(category, ':id', {
          id
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDArInvoice2 = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ar
    }),
    webTable: WebTable.CONSOL_AR_INVOICES,
    queryKey: QUERY_KEYS.arInvoice,
    dependentWebTable: [
      WebTable.SHIPMENT_AR_INVOICES,
      WebTable.SERVICE_AR_INVOICES
    ]
  });
};

interface IUseStatusMutationEnhancerParams {
  suffix?: string;
  category: InvoiceCategory;
  arApType: ArApType;
}

const MUTATION_MAPPED_DATA = {
  [ArApType.Ar]: {
    queryKey: QUERY_KEYS.arInvoice,
    webTables: [
      WebTable.CONSOL_AR_INVOICES,
      WebTable.SHIPMENT_AR_INVOICES,
      WebTable.SERVICE_AR_INVOICES
    ]
  },
  [ArApType.Ap]: {
    queryKey: QUERY_KEYS.apInvoice,
    webTables: [
      WebTable.CONSOL_AP_INVOICES,
      WebTable.SHIPMENT_AP_INVOICES,
      WebTable.SERVICE_AP_INVOICES
    ]
  }
};

const useStatusMutationEnhancer = (d: IUseStatusMutationEnhancerParams) => {
  const { suffix, category, arApType } = d;

  const queryClient = useQueryClient();

  const mData = MUTATION_MAPPED_DATA[arApType];

  return useMutationEnhancer<boolean | undefined>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.accounting(
          {
            category,
            type: arApType
          },
          `:id/${suffix}`,
          data
        ),
        method: 'PUT',
        showToast: true,
        body: data
      });
      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        mData.webTables?.forEach(i => {
          tableRef?.[i]?.init?.();
        });
        if (mData.queryKey) {
          queryClient.invalidateQueries([mData.queryKey], {
            type: 'active'
          });
        }
      }
    }
  });
};

export const useCUDStatusArInvoice2 = (category: InvoiceCategory) => {
  const mutationEnhancer = (
    d: Pick<IUseStatusMutationEnhancerParams, 'suffix'>
  ) =>
    useStatusMutationEnhancer({
      category,
      arApType: ArApType.Ar,
      ...d
    });

  const [nextMutation, backMutation, cancelMutation] = [
    'next-status',
    'previous-status',
    'cancel'
  ].map(i => mutationEnhancer({ suffix: i }));

  return {
    nextMutation,
    backMutation,
    cancelMutation,
    isLoading:
      nextMutation.isLoading ||
      backMutation.isLoading ||
      cancelMutation.isLoading
  };
};

export const useCUDApInvoice2 = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ap
    }),
    webTable: WebTable.CONSOL_AP_INVOICES,
    queryKey: QUERY_KEYS.apInvoice,
    dependentWebTable: [
      WebTable.SHIPMENT_AP_INVOICES,
      WebTable.SERVICE_AP_INVOICES
    ]
  });
};

export const useCUDStatusApInvoice2 = (category: InvoiceCategory) => {
  const mutationEnhancer = (
    d: Pick<IUseStatusMutationEnhancerParams, 'suffix'>
  ) =>
    useStatusMutationEnhancer({
      category,
      arApType: ArApType.Ap,
      ...d
    });

  const [nextMutation, backMutation, cancelMutation] = [
    'next-status',
    'previous-status',
    'cancel'
  ].map(i => mutationEnhancer({ suffix: i }));

  return {
    nextMutation,
    backMutation,
    cancelMutation,
    isLoading:
      nextMutation.isLoading ||
      backMutation.isLoading ||
      cancelMutation.isLoading
  };
};

interface IUseInvoiceActions {
  category: InvoiceCategory;
  arApType: ArApType;
  id?: string | number;
  status?: ARInvoiceStatus | APInvoiceStatus;
}

type IInvoiceAction = 'NEW' | 'NEXT' | 'BACK' | 'CANCEL';

export const useArInvoiceStatusForActions = (
  status?: ARInvoiceStatus | APInvoiceStatus
): IInvoiceAction[] => {
  switch (status) {
    case ARInvoiceStatus.DRAFT:
    case ARInvoiceStatus.CONFIRMED:
    case ARInvoiceStatus.CONFIRM_REQUEST:
    case ARInvoiceStatus.INVOICE_REQUEST:

    case APInvoiceStatus.DRAFT:
    case APInvoiceStatus.PAYMENT_REQUEST:
    case APInvoiceStatus.VERIFIED:
      return ['NEW', 'NEXT', 'BACK', 'CANCEL'];

    case ARInvoiceStatus.ISSUED:
    case APInvoiceStatus.APPROVED:
      return ['NEW', 'CANCEL'];

    case ARInvoiceStatus.COMPLETE:
    case ARInvoiceStatus.CANCEL:

    case APInvoiceStatus.COMPLETE:
    case APInvoiceStatus.CANCEL:
      return ['NEW'];

    default:
      return [];
  }
};

const MAPPED_INVOICE_ACTION: Record<IInvoiceAction, any> = {
  ['NEXT']: {
    title: 'send_invoice_status',
    icon: 'ArrowUpward'
  },
  ['BACK']: {
    title: 'back_invoice_status',
    icon: 'ArrowDownward'
  },
  ['CANCEL']: {
    title: 'cancel',
    icon: 'CancelOutlined',
    color: KColors.secondary.normal
  },
  ['NEW']: {
    title: 'new',
    icon: 'AddBox'
  }
};

export const useInvoiceActions = ({
  arApType,
  category,
  id,
  status
}: IUseInvoiceActions) => {
  const navigate = useNavigate();

  const arStatusMutation = useCUDStatusArInvoice2(category);

  const apStatusMutation = useCUDStatusApInvoice2(category);

  const isAr = arApType === ArApType.Ar;

  const {
    nextMutation: { mutate: nextMutation },
    backMutation: { mutate: backMutation },
    cancelMutation: { mutate: cancelMutation },
    isLoading
  } = isAr ? arStatusMutation : apStatusMutation;

  const setAction = useCallback(
    (action: IInvoiceAction) => {
      let onPress: () => void;

      switch (action) {
        case 'NEXT':
          onPress = () => nextMutation({ id });
          break;

        case 'BACK':
          onPress = () => backMutation({ id });
          break;

        case 'CANCEL':
          onPress = () => cancelMutation({ id });
          break;

        default:
          onPress = () => navigate('../new');
          break;
      }

      const {
        title,
        icon,
        color = KColors.primary.normal
      } = MAPPED_INVOICE_ACTION[action] || {};

      return {
        title: trans(title),
        icon: {
          name: icon,
          color: color
        },
        onPress
      };
    },
    [backMutation, cancelMutation, id, navigate, nextMutation]
  );

  const actionKeys = useArInvoiceStatusForActions(status);

  const actions = useMemo(() => {
    return actionKeys.map(i => setAction(i));
  }, [actionKeys, setAction]);

  return { actions, isLoading };
};
