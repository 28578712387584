import { IUseAgTableProps, IUseAgTableResult } from '@hooks-dto';
// import { exportDataToExcel } from '@utils/excel';
import {
  CellValueChangedEvent,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  DragStoppedEvent,
  GridReadyEvent
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep, isEmpty, set } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

const useAgTable = <T = any>({
  name,
  data,
  emptyData,
  colDefs,

  isSuccess,

  onAdd,
  onAddParams,

  onEndEditing,

  onExportParams
}: IUseAgTableProps<T>): IUseAgTableResult<T> => {
  const gridRef = useRef<AgGridReact<T>>(null);

  const [isGridReady, setIsGridReady] = useState<boolean>(false);

  const [rowData, setRowData] = useState<T[]>(data ?? []);
  const [selectedData, setSelectedData] = useState<T[]>([]);

  const [keyword, setKeyword] = useState<string>('');

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isEmpty(data) && JSON.stringify(rowData) !== JSON.stringify(data)) {
      setRowData(data as T[]);
    } else if (!isMounted.current && isSuccess && isEmpty(data) && emptyData) {
      isMounted.current = true;
      setRowData(emptyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), rowData, isSuccess]);

  useEffect(() => {
    gridRef.current?.api?.setGridOption('quickFilterText', keyword);
  }, [keyword]);

  const onGetData = useCallback(() => {
    const rows: T[] = [];
    gridRef.current?.api?.forEachNode(rowNode => rows.push(rowNode.data as T));
    return rows;
  }, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<T>) => {
    setSelectedData(event.api.getSelectedRows());
  }, []);

  const onAddLastRow = useCallback(() => {
    onAdd?.() ??
      gridRef.current?.api.applyTransaction({
        add: [{ action: 'addLastRow', ...onAddParams?.initialValue } as T],
        addIndex: 0
      });
  }, [onAdd, onAddParams?.initialValue]);

  const onEndEditingRows = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNode(rowNode => rows.push(rowNode.data as T));
      if (JSON.stringify(rowData) != JSON.stringify(rows)) {
        onEndEditing?.(rows);
      }
    },
    [onEndEditing, rowData]
  );

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNode(rowNode => rows.push(rowNode.data as T));
      onEndEditing?.(rows);
    },
    [onEndEditing]
  );

  const onDragStopped = useCallback(
    (event: DragStoppedEvent<any>) => {
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const _configs = cloneDeep(agGridConfigs);
      set(_configs, `columnStates.${name}`, event.api.getColumnState());
      StorageEnhance.set(STORAGE_KEYS.agGrid, _configs);
    },
    [name]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent<any>) => {
      setIsGridReady(true);
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const _columnState = agGridConfigs?.columnStates?.[name];
      if (_columnState) {
        event.api.applyColumnState({ state: _columnState, applyOrder: true });
      }
    },
    [name]
  );

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const _columnState = agGridConfigs?.columnStates?.[name];
      if (_columnState && isGridReady) {
        event.api.applyColumnState({ state: _columnState, applyOrder: true });
      }
    },
    [isGridReady, name]
  );

  const onExport = useCallback(
    (fileName?: string) => {
      if (gridRef.current) {
        const exportData =
          gridRef.current.api
            .getDataAsCsv({
              suppressQuotes: true,
              columnSeparator: '__'
            })
            ?.split('\r\n')
            .map(i => {
              return i.split('__');
            }) ?? [];

        const columnWidths = gridRef.current.api
          .getColumnState()
          .map(i => i.width) as number[];

        const exportParams = {
          ...onExportParams,
          columnWidths
        };

        // await exportDataToExcel(
        //   fileName || 'report.xlsx',
        //   exportData,
        //   exportParams
        // );
      }
    },
    [onExportParams]
  );

  return {
    gridRef,

    name,

    rowData,
    columnDefs: colDefs,
    selectedData,
    keyword,
    onAddParams,

    onAdd: onAddParams?.hidden ? undefined : onAddLastRow,
    onExport,
    onSearch: setKeyword,
    onGetData,

    onSelectionChanged,
    onRowDataUpdated,
    onRowEditingStopped: onEndEditingRows,
    onCellValueChanged,
    onDragStopped,
    onGridReady
  };
};

export default useAgTable;
