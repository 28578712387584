import {
  ENDPOINTS,
  PaymentType,
  QUERY_KEYS,
  StatementInvoiceType,
  StatementOfAccountStatus,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

interface IGenerateProps {
  statementOfAccountId: number | string;
  currencyId: number | string;
  clientId: number | string;
  invoiceType: StatementInvoiceType;
  fromIssueDate: any;
  toIssueDate: any;
}

export const useFetchStatementOfAccount = (soaId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementOfAccount, soaId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementOfAccount(':soaId', { soaId })
      });

      return res.data;
    },
    enabled: !!soaId
  });
};

export const useFetchStatementOfAccountList = (mParams: {
  clientId: string;
  statementType: PaymentType;
  currencyId: string;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementOfAccountList, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementOfAccount(),
        body: {
          statementStatuses: [
            StatementOfAccountStatus.ACTIVE,
            StatementOfAccountStatus.PARTLY_PAID
          ],
          ...mParams,
          includeFields: [
            'details',
            'client',
            'clientAddress',
            'companyBank',
            'currency',
            'paymentMode'
          ]
        }
      });

      return res.data?.data;
    }
  });
};

export const useGenerateStatementOfAccount = () => {
  return useMutationEnhancer<any, IGenerateProps>({
    mutationFn: async body => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementOfAccount('generate'),
        body,
        method: 'GET'
      });
      return (res?.data || []).map((o: any) => ({ ...o, checked: false }));
    }
  });
};

export const useCUDStatementOfAccount = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.statementOfAccount(),
    webTable: WebTable.OTHER
  });
};

export const useCUDStatementInvoice = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.statementInvoice(),
    webTable: WebTable.OTHER
  });
};

export const useFetchStatementInvoice = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementInvoice, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementInvoice(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};
