import { compact, snakeCase } from 'lodash';
import React, { createRef, memo, useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { UITab } from '@constants';
import ServiceProvider from 'context/Service';
import {
  useFetchService,
  useFetchShipmentContainer,
  useUITabsWithCallback
} from 'hooks';
import { useFetchServiceContainerDetails } from 'hooks/consol/service';
import trans from 'translation';
import { KBreadcrumbs, KColors, KContainer, KTab } from 'uikit';

import { checkLinkToShipment } from './helper';

const DetailsLayout = () => {
  const { serviceId, serviceContainerId } = useParams();

  const { data: item } = useFetchService(serviceId);

  const isLinkToShipment = checkLinkToShipment(item?.shipmentId);

  const { data: shipmentContainerDetails } = useFetchShipmentContainer(
    //link_shipment
    serviceContainerId,
    item?.shipmentId
  );
  const { data: serviceContainerDetails } = useFetchServiceContainerDetails(
    // unlink_shipment
    serviceContainerId,
    isLinkToShipment,
    !!item
  );

  const containerDetails = useMemo(() => {
    if (isLinkToShipment) {
      return shipmentContainerDetails;
    }
    return serviceContainerDetails;
  }, [isLinkToShipment, serviceContainerDetails, shipmentContainerDetails]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabsRef = createRef<any>();

  const cb = useCallback(() => {
    let _tabs = compact([
      {
        key: UITab.Service,
        label: trans('tab.general'),
        path: ''
      },
      {
        key: UITab.ServiceCustoms,
        label: trans('tab.customs'),
        path: 'customs',
        disabled: !item?.isCustoms
      },
      {
        key: UITab.ServiceTransport,
        label: trans('tab.transport'),
        path: 'transport',
        disabled: !item?.isTransport
      },
      // {
      //   key: keyClient.HANDLING,
      //   label: trans('tab.handling'),
      //   path: 'handling',
      //   disabled: !item?.isHandling,
      // },
      {
        key: UITab.ServiceContainers,
        path: 'containers-packing',
        label: trans('tab.containers_packing')
      },
      !isLinkToShipment
        ? {
            key: UITab.ServiceBilling,
            path: 'billing',
            label: trans('tab.billing')
          }
        : undefined,
      {
        key: UITab.ServiceAlertNote,
        label: trans('tab.alert_note'),
        path: 'alert-note'
      },
      {
        key: UITab.ServiceDocument,
        path: 'document',
        label: trans('tab.document')
      }
    ]);

    if (!serviceId) {
      _tabs = _tabs.map(i => {
        if (i.key === UITab.Service) {
          return i;
        }
        return { ...i, disabled: true };
      });
    }

    return _tabs;
  }, [isLinkToShipment, item?.isCustoms, item?.isTransport, serviceId]);

  const tabs = useUITabsWithCallback(cb);

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
    },
    [navigate, tabs]
  );

  useEffect(() => {
    const arr = pathname.split('/').slice(4)?.[0] ?? '';
    if (tabs.find(i => i.path === arr)) {
      tabsRef.current?.onChange?.(
        tabs.findIndex(i => i.path === arr),
        true
      );
    }
  }, [pathname, tabs, tabsRef]);

  const breadcrumbs = useMemo(() => {
    const arr = pathname
      .split('/')
      .slice(3)
      .filter(i => i);

    const bc = [trans('routes.service')];

    bc.push(
      ...arr.map((i, idx) => {
        const isLastItem = idx === arr.length - 1;
        let title = trans(`routes.${snakeCase(i)}`);
        const isNumber = /^[0-9]+$/.test(i);

        if ((idx === 0 || idx === 3) && isNumber) {
          title = item?.code;
        }

        if (idx === 2 && isNumber) {
          title = containerDetails?.containerNumber;
        }

        return {
          title,
          onPress: isLastItem ? undefined : () => navigate(idx - arr.length + 1)
        };
      })
    );
    return bc;
  }, [pathname, item, containerDetails?.containerNumber, navigate]);

  return (
    <>
      <ServiceProvider>
        <KBreadcrumbs
          hasBackIcon
          breadcrumbs={breadcrumbs}
          onBack={() => navigate('../')}
        />

        <KContainer.Card
          padding="0rem"
          background={KColors.transparent}
          noShadow
        >
          <KTab.Solid
            ref={tabsRef}
            tabs={tabs}
            onChangeTab={onChangeTab}
            marginT={0}
            background={KColors.white}
            kind="background"
            activeBackground={KColors.primary.normal}
            textColor={KColors.white}
          />

          <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
            <Outlet />
          </KContainer.View>
        </KContainer.Card>
      </ServiceProvider>
    </>
  );
};

export default memo(DetailsLayout);
