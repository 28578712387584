import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ENDPOINTS,
  TABLE_BODY_MAX_HEIGHT_WITH_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable } from 'hooks';
import trans from 'translation';
import { KBreadcrumbs, KColors, KContainer, KLabel } from 'uikit';
import { TableUtils, UIUtils, mappedServiceStatus } from 'utils';
import { showNoteContent } from 'utils/common';

import { useDeleteService } from './helper';

const Service = () => {
  const { onDelete, deleteLoading } = useDeleteService();

  const navigate = useNavigate();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => {
                navigate(`${item.id}`);
              }
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('service_no'),
          name: 'code',
          options: {
            ...TableUtils.options.baseXLg,
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              return (
                <KLabel.Text
                  onPress={() => {
                    navigate(`./${item?.id}`);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('hbl_hawb'),
          name: 'hblNo',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('obl_mawb'),
          name: 'oblNo',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('service_type'),
          name: 'serviceType',
          options: {
            ...TableUtils.options.baseCenter,
            sort: false,
            customBodyRenderLite: (index: number) => {
              const rowData = data?.[index];
              if (rowData) {
                const { isCustoms, isHandling, isTransport } = rowData;
                return (
                  <KContainer.View row dp="flex" center>
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={isCustoms}>
                        <KContainer.View
                          paddingH="0.25rem"
                          marginR={'0.25rem'}
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            CU
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isTransport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          marginR={'0.25rem'}
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            TR
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isHandling}>
                        <KContainer.View
                          paddingH="0.25rem"
                          marginR={'0.25rem'}
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            HA
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },

        {
          label: trans('shipment_no'),
          name: 'shipmentNumber',
          options: {
            ...TableUtils.options.baseXMd,
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              const to = item?.consolId
                ? `/consolidation/${item?.consolId}/shipment/${item?.shipmentId}`
                : `/shipment/${item?.shipmentId}`;

              return (
                <KLabel.Text
                  onPress={() => {
                    window.open('/admin/ocean-freight' + to);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('status'),
          name: 'serviceStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 152,
            css: { textAlign: 'center' },
            mapLabelData: mappedServiceStatus()
          })
        },
        {
          label: trans('booking_client_code'),
          name: 'bookingClient.code'
        },
        {
          label: trans('booking_client_name'),
          name: 'bookingClient.name'
        },

        {
          label: trans('container_no'),
          name: 'containerNumberList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: v => {
              UIUtils.popup.open({
                title: trans('container_no'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`container_no-popup-${i}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.primary.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">{i}</KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i: string, idx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KContainer.View key={`container_no-${i}`} marginL={marginL}>
                  <KLabel.Text color={KColors.primary.normal}>{i}</KLabel.Text>
                </KContainer.View>
              );
            },
            onNormalizeData: (rowData: any) => {
              const item = data?.[rowData.rowIndex];
              if (item) {
                return item?.containerNumberList?.split(',');
              }

              return [];
            }
          })
        },

        {
          label: trans('cds_no'),
          name: 'customDeclarationSheetList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: v => {
              UIUtils.popup.open({
                title: trans('cds_no'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`shipment-popup-${i}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.primary.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">{i}</KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i: string, idx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KContainer.View
                  key={`custom-declaration-sheet-list-${i}`}
                  marginL={marginL}
                >
                  <KLabel.Text color={KColors.primary.normal}>{i}</KLabel.Text>
                </KContainer.View>
              );
            },
            onNormalizeData: (rowData: any) => {
              const item = data?.[rowData.rowIndex];
              if (item) {
                return item?.customDeclarationSheetList?.split(',') ?? '';
              }

              return [];
            }
          })
        },
        {
          label: trans('job_order_no'),
          name: 'jobOrderNumber',
          options: {
            ...TableUtils.options.base
          }
        },
        {
          label: trans('sales_person'),
          name: 'picSalesPerson.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('pic_customs'),
          name: 'picCustoms.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('pic_transport') + 1,
          name: 'picTransport.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        },
        {
          label: trans('general_note'),
          name: 'noteContent',
          options: TableUtils.options.contentMore((item: any) => {
            showNoteContent(item);
          }, data)
        }
      ];
    },
    [navigate, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: [
        'bookingClient',
        'generalService',
        'picSalesPerson',
        'picCustoms',
        'picTransport'
      ],

      isReconcile: true
    };
  }, []);

  const mappedFields = useMemo(() => {
    return {
      'bookingClient.code': 'bookingClientCode',
      'bookingClient.name': 'bookingClientName'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.SERVICE,
    tableName: TableName.SERVICE,
    apiURL: ENDPOINTS.service(),
    columnsFunc,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITH_TABS
    },
    otherParams,
    mappedFields
  });

  useGlobalTable(table);

  return (
    <>
      <KBreadcrumbs breadcrumbs={[trans('service')]} />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={() => navigate('./new')}
          onDelete={onDelete}
          isLoading={table.isLoading || deleteLoading}
        />
      </KContainer.Card>
    </>
  );
};

export default memo(Service);
