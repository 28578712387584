import React, { createRef, memo, useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { GroupAccounting, UITab } from '@constants';
import { useUITabsWithCallback } from 'hooks';
import trans from 'translation';
import { KContainer, KTab } from 'uikit';

import { useAccountingBreadcrumbs } from './helpers';

const DetailsLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabsRef = createRef<any>();

  const cb = useCallback(() => {
    const _tabs = [
      {
        key: UITab.Accounting,
        path: 'dashboard',
        label: trans('tab.dashboard'),
        code: [GroupAccounting.ACCOUNTING_DASHBOARD]
      },
      {
        key: UITab.AccountingSales,
        path: 'sales',
        label: trans('tab.sales'),
        code: [
          GroupAccounting.ACCOUNTING_AR_INVOICE,
          GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE
          // GroupAccounting.ACCOUNTING_AR_INVOICE_SUMMARY
        ]
      },
      {
        key: UITab.AccountingPurchases,
        path: 'purchases',
        label: trans('tab.purchases'),
        code: [
          GroupAccounting.ACCOUNTING_AP_INVOICE,
          GroupAccounting.ACCOUNTING_PAYMENT_ADVICE
        ]
      },
      {
        key: UITab.AccountingStatement,
        path: 'statement',
        label: trans('tab.statement')
      },
      {
        key: UITab.AccountingBanking,
        path: 'banking',
        label: trans('tab.banking'),
        code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT]
      },
      // {
      //   key: 'Report',
      //   path: 'report',
      //   label: trans('tab.report')
      // },
      // {
      //   key: 'Document',
      //   path: 'document',
      //   label: trans('tab.document')
      // },
      {
        key: UITab.AccountingMore,
        path: 'more',
        label: trans('tab.more'),
        code: [
          GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT,
          GroupAccounting.ACCOUNTING_LINKED_ACCOUNT,
          GroupAccounting.ACCOUNTING_TAX_CODE
        ]
      }
    ];

    return _tabs;
  }, []);

  const tabs = useUITabsWithCallback(cb, {
    defFb: '/admin/accounting'
  });

  useEffect(() => {
    const arr = pathname.split('/').slice(3)[0] ?? '';
    if (tabs.find(i => i.path === arr)) {
      tabsRef.current?.onChange?.(
        tabs.findIndex(i => i.path === arr),
        true
      );
    }
  }, [pathname, tabs, tabsRef]);

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
    },
    [navigate, tabs]
  );

  const breadcrumbs = useAccountingBreadcrumbs(pathname);

  if (tabs.length === 0) return null;

  return (
    <>
      {breadcrumbs}

      <KTab.Main ref={tabsRef} tabs={tabs} onChangeTab={onChangeTab} />

      <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
        <Outlet />
      </KContainer.View>
    </>
  );
};

export default memo(DetailsLayout);
