import { IApChargeCode, IArChargeCodes } from '@invoice';
import { IRowNode } from 'ag-grid-community';
import React from 'react';

import {
  AirContainerMode,
  DataCal,
  InvoiceType,
  LevelMarkup,
  OceanContainerMode,
  WipServiceType
} from '@constants';
import { formatToFixed } from 'containers/FromWip/helpers';
import { Prototype } from 'core';
import { useFetchGetListBuyTransfer } from 'hooks';
import { subTotalTaxAmount } from 'pages/Service/common/helper';
import trans from 'translation';
import { UIUtils } from 'utils';

import FormUnitPriceQuantityAP from './AP/Detail/ChargeCodeList/Form.UnitPriceQuantity';
import FormUnitPriceQuantity from './AR/Details/ChargeCodeList/Form.UnitPriceQuantity';

export const unitPriceKey = (contMode: any) => {
  const obj = {
    FCL: 'fclUnit',
    LCL: 'lclUnit',
    FCX: 'fcxUnit',
    FAK: 'fakUnit',
    ULD_FCL: 'uldUnit',
    LSE_LCL: 'lseUnit',
    ULD_FAK: 'othUnit',
    BCN_FCX: 'bcnUnit'
  };
  if (!contMode) return null;
  return obj[contMode];
};

export const onShowUnitPriceQuantityValue = (
  dataCal: DataCal,
  type: 'AP' | 'AR',
  node: IRowNode<IArChargeCodes | IApChargeCode>,
  paymentCurrency: any,
  invoiceType: InvoiceType,
  contMode?: OceanContainerMode | AirContainerMode,
  callBackNodeData?: (v: any) => void
) => {
  UIUtils.popup.open({
    title: trans('unit_price_quantity'),
    maxWidth: 'lg',
    content: () => {
      return type === 'AR' ? (
        <FormUnitPriceQuantity
          node={node as IRowNode<IArChargeCodes>}
          paymentCurrency={paymentCurrency}
          invoiceType={invoiceType}
          contMode={contMode}
          callBackNodeData={callBackNodeData}
          dataCal={dataCal}
        />
      ) : (
        <FormUnitPriceQuantityAP
          node={node as IRowNode<IApChargeCode>}
          paymentCurrency={paymentCurrency}
          invoiceType={invoiceType}
          contMode={contMode}
          callBackNodeData={callBackNodeData}
          dataCal={dataCal}
        />
      );
    }
  });
};

export const formaValCurrency = (val: number) => {
  const formatVal =
    val < 0
      ? `(${Prototype.number.formatNumber(-1 * val)})`
      : Prototype.number.formatNumber(val);

  return formatVal;
};

export const amountComposite = (
  isCreditNote: boolean,
  subChargeCodes?: any[],
  baseValue?: number,
  rate?: number,
  taxType?: any
) => {
  const newSubChargeCode = (subChargeCodes || []).map(itemA => {
    return {
      ...itemA,
      subChargeCode:
        itemA?.subChargeCode?.subChargeCode || itemA?.subChargeCode,
      subChargeCodeId:
        itemA?.subChargeCode?.subChargeCode?.id || itemA?.subChargeCode?.id,
      estTotal:
        ((Number(itemA?.subTotal) || 0) * (Number(itemA?.ratio) || 0)) / 100
    };
  });

  const _subTotal =
    (newSubChargeCode || []).reduce((acc, cur) => {
      acc = acc + cur.estTotal;
      return acc;
    }, 0) + (Number(baseValue) || 0);

  const subTotal = isCreditNote ? -1 * _subTotal : _subTotal;

  const paymentSubTotal = (Number(subTotal) || 0) * (Number(rate) || 0);

  const paymentTaxAmount =
    ((Number(taxType?.defaultRate) || 0) * (Number(paymentSubTotal) || 0)) /
    100;

  const paymentTotalAmount = paymentSubTotal + paymentTaxAmount;

  return {
    newSubChargeCode,
    subTotal,
    paymentSubTotal,
    paymentTaxAmount,
    paymentTotalAmount
  };
};

export const amountValue = (
  isCreditNote: boolean,
  chargeCodeLevels?: any,
  rate?: any,
  taxType?: any
) => {
  const newChargeCodeLevels = (chargeCodeLevels || []).map((item: any) => {
    return {
      ...item,
      estTotal: +formatToFixed(
        (Number(item?.quantity) || 0) * (Number(item?.unitPrice) || 0)
      ),
      unitPrice: +formatToFixed(Number(item?.unitPrice) || 0)
    };
  });

  const _subTotal = (newChargeCodeLevels ?? []).reduce(
    (v: number, currentV: any) => {
      const vAmount =
        (Number(currentV?.quantity) || 0) * (Number(currentV?.unitPrice) || 0);
      return Number(v) + vAmount;
    },
    0
  );

  const subTotal = isCreditNote ? -1 * _subTotal : _subTotal;

  const paymentSubTotal = (Number(subTotal) || 0) * (Number(rate) || 0);

  const paymentTaxAmount =
    ((Number(taxType?.defaultRate) || 0) * (Number(paymentSubTotal) || 0)) /
    100;

  const paymentTotalAmount =
    (Number(paymentSubTotal) || 0) + (Number(paymentTaxAmount) || 0);

  return {
    subTotal,
    paymentSubTotal,
    paymentTaxAmount,
    paymentTotalAmount,
    newChargeCodeLevels
  };
};

export const amountDefault = {
  subTotal: 0,
  paymentSubTotal: 0,
  paymentTaxAmount: 0,
  paymentTotalAmount: 0,
  quantity: 1
};

export const funcDefaultExRate = async (
  chargeCodeV: any,
  paymentCurrencyId: number,
  exchangeRateDate: any
) => {
  const chargeCodeCurrencyId = chargeCodeV?.currencyId;
  const isCurrency = chargeCodeCurrencyId === paymentCurrencyId;

  let defaultExRate;
  if (isCurrency) {
    defaultExRate = 1;
  } else {
    const dataExchangeRate = await useFetchGetListBuyTransfer(
      chargeCodeCurrencyId,
      paymentCurrencyId,
      exchangeRateDate
    );

    defaultExRate = dataExchangeRate?.buyRateTransfer ?? 1;
  }
  return defaultExRate;
};

export const listExchangeRate = async (
  listChargeCodes: any[],
  paymentCurrencyId: number,
  exchangeRateDate: any
) => {
  const listPromise = listChargeCodes.map((item: any) => {
    return funcDefaultExRate(item, paymentCurrencyId, exchangeRateDate);
  });

  try {
    const res = await Promise.all(listPromise);
    return res || [];
  } catch (err) {
    return [];
  }
};

export const listRowData = (
  listChargeCodes: any[],
  type: 'AR' | 'AP',
  contMode?: any
) => {
  const dataChargeCode =
    type === 'AP' ? covertDataToChargeCodeAp : covertDataToChargeCodeAr;

  const res = listChargeCodes.map(item => {
    return {
      description: item.name,
      ...dataChargeCode(item, contMode)
    };
  });

  return res;
};

export const covertDataToChargeCode = (
  v: any,
  type: 'AR' | 'AP',
  contMode?: any
) => {
  return type === 'AR'
    ? covertDataToChargeCodeAr(v, contMode)
    : covertDataToChargeCodeAp(v, contMode);
};

const covertDataToChargeCodeAr = (v: any, contMode?: any) => {
  const keyUnitPrice = unitPriceKey(contMode);
  const { taxType, taxTypeId } = subTotalTaxAmount({
    taxType: v?.taxType,
    _estSubTotal: 0
  });
  return {
    chargeCode: v,
    chargeCodeId: v?.id,
    chargeCodeUnit: v?.chargeCodeUnit || v[keyUnitPrice],
    chargeCodeUnitId: v?.chargeCodeUnitId || v[keyUnitPrice]?.id,
    currency: v?.currency,
    currencyId: v?.currency?.id,
    accountPayable: v?.accountPayable,
    arSubChargeCodes: v?.arSubChargeCodes,
    serviceType: WipServiceType.Origin,
    markupLevelNumber: LevelMarkup.MarkupLevel1,
    uplift: 0,
    revenueAccount: v?.revenueAccount,
    revenueAccountId: v?.revenueAccount?.id,
    taxType,
    taxTypeId,
    ...amountDefault
  };
};

const covertDataToChargeCodeAp = (v: any, contMode?: any) => {
  const keyUnitPrice = unitPriceKey(contMode);
  const { taxType, taxTypeId } = subTotalTaxAmount({
    taxType: v?.taxType,
    _estSubTotal: 0
  });
  return {
    chargeCode: v,
    chargeCodeId: v.id,
    chargeCodeUnit: v?.chargeCodeUnit || v[keyUnitPrice],
    chargeCodeUnitId: v?.chargeCodeUnitId || v[keyUnitPrice]?.id,
    currency: v?.currency,
    currencyId: v?.currency?.id,
    apSubChargeCodes: v?.apSubChargeCodes,
    serviceType: WipServiceType.Origin,
    costAccount: v?.costAccount,
    costAccountId: v?.costAccount?.id,
    taxType,
    taxTypeId,
    ...amountDefault
  };
};
